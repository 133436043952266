<template>
  <div class="message-nav">
    <div class="left-side" v-if="isLeft">
      <!-- Load previous alternative -->
      <div class="alt-wrapper">
        <vs-button
          class="message-nav-btn previous-alternative-btn"
          color="primary"
          type="filled"
          :badge="1"
          :disabled="hasPreviousAlternative"
          :title="lang.botMaker.navBar.previous[languageSelected]"
          @click="loadPreviousAlternative"
          icon="arrow_back"
        ></vs-button>
        <div class="badge" v-show="!hasPreviousAlternative">
          {{ userMessageInEdit.numberOfPrevAltPaths }}
        </div>
      </div>

      <!-- Delete message -->
      <vs-button
        class="message-nav-btn"
        color="primary"
        type="filled"
        icon-pack="feather"
        icon="icon-trash"
        :disabled="readOnly || (!isMessageSelected && userMessageInEdit.isSaved)"
        :title="lang.botMaker.navBar.delete[languageSelected]"
        @click="deleteMessage"
      ></vs-button>

      <!-- Integrations -->
      <div class="alt-wrapper">
        <vs-button
          class="message-nav-btn"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-zap"
          :title="lang.botMaker.navBar.integrations[languageSelected]"
          @click="openIntegrationPopupRef()"
          :disabled="!isMessageSelected"
        ></vs-button>
        <div class="badge" v-show="apiCallsBadge">{{ apiCallsBadge }}</div>
      </div>

      <IntegrationPopup
        ref="integrationPopup"
        :open.sync="integrationsPopupActive"
        :read-only="readOnly"
      ></IntegrationPopup>

      <!-- Jumps -->
      <div class="alt-wrapper">
        <vs-button
          class="message-nav-btn"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-corner-up-right"
          :title="lang.botMaker.navBar.jump[languageSelected]"
          @click="openJumpPopup"
          :disabled="!isMessageSelected"
        ></vs-button>
        <div class="badge" v-show="hasJump">1</div>
      </div>

      <vs-popup
        class="message-nav-popup"
        :title="lang.botMaker.jump.title[languageSelected]"
        :active.sync="jumpPopupActive"
        icon-pack="feather"
        icon-close="icon-x"
        style="padding: 0px;"
        @close="onCloseJumps"
      >
        <div v-if="dialogJump" class="jumpHeader">
          <p class="current-jump">
            <vs-icon color="primary" icon="warning"></vs-icon>
            {{ lang.botMaker.jump.current[languageSelected] }}
            <span>{{ dialogJump.name }}</span>
            <vs-button
                    type="flat"
                    size="small"
                    icon-pack="feather"
                    icon="icon-corner-up-right"
                    class="flex-wrap"
                    @click="navigateToDialog(dialogJump)"
            ></vs-button>
          </p>
          <vs-divider />
        </div>

        <KonaTree
          v-if="jumpPopupActive"
          :tpl="'basic'"
          :key="keyRefresh"
          :tree-data="treeData"
          :searchBar="true"
          :showJump="false"
          :halfcheck="false"
          :multiple="false"
          :radio="true"
          :canMove="false"
          :serachWidht="'w-1/2'"
          :marginTop="dialogJump ? 'mt-0' : 'mt-2'"
          @clickNode="clickNode"
          ref="konaTree"
        />

        <div class="jumpFooter" v-if="!readOnly">
          <vs-divider />
          <vs-row
            vs-align="center"
            vs-type="flex"
            vs-justify="space-around"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button
                @click="deleteJump"
                color="danger"
                :disabled="!hasJump"
              >
                {{ lang.botMaker.jump.remove.title[languageSelected] }}
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button @click="updateJump" :disabled="disableAddJump">
                {{ lang.botMaker.jump.update.title[languageSelected] }}
              </vs-button>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="4"
            >
              <vs-button @click="closeJumpPopup" color="danger" type="flat">
                {{ lang.botMaker.integrations.cancel[languageSelected] }}
              </vs-button>
            </vs-col>
          </vs-row>
        </div>
      </vs-popup>
    </div>

    <div class="alt-wrapper" v-else>
      <div class="right-side">
        <!-- Load next alternative -->
        <vs-button
          class="message-nav-btn next-alternative-btn"
          color="primary"
          type="filled"
          icon="arrow_forward"
          :disabled="hasNextAlternative"
          :title="lang.botMaker.navBar.next[languageSelected]"
          @click="loadNextAlternative"
        ></vs-button>
        <div class="badge" v-show="!hasNextAlternative">
          {{ userMessageInEdit.numberOfNextAltPaths }}
        </div>
      </div>

      <!-- Create alternative path -->
      <vs-button
        class="message-nav-btn"
        color="primary"
        type="filled"
        icon="subdirectory_arrow_right"
        :disabled="readOnly || isCreateAlternativeDisabled"
        :title="lang.botMaker.navBar.path[languageSelected]"
        @click="createAlternativePath"
      ></vs-button>

      <!-- Context Variables -->
      <div class="alt-wrapper">
        <vs-button
          class="message-nav-btn"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-database"
          :title="lang.botMaker.navBar.contextVariables[languageSelected]"
          @click="toggleContextVariables"
          :disabled="!isMessageSelected"
        ></vs-button>
        <div class="badge" v-show="contextVariablesBadge">
          {{ contextVariablesBadge }}
        </div>
      </div>

      <ContextVariables
        v-if="dialogId"
        :dialogId="dialogId"
        ref="contextVariables"
        :read-only="readOnly"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import BotMakerService from '../../../../../../services/botMaker.service'
import ContextVariables from './ContextVariables'
import botTreeMixin from '@/mixins/botTreeMixin'

export default {
  name: 'MessageNav',
  data() {
    return {
      integrationsPopupActive: false,
      jumpPopupActive: false,
      selectedApis: [],
      selectedNode: null,
      disableAddJump: true,
      keyRefresh: new Date().getTime()
    }
  },
  props: {
    isLeft: Boolean,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    jumpPopupActive(newVal) {
      if (newVal == true) {
        this.keyRefresh = new Date().getTime()
      }
    }
  },
  mixins: [botTreeMixin],
  components: {
    ContextVariables,
    IntegrationPopup: () => import('./Components/Integration.vue'),
    KonaTree: () => import('../../../../../../components/KonaTree.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'currentDialogHasSurveys',
      'userMessageInEdit',
      'botMessageInEdit',
      'botMakerMessages',
      'bot',
      'apiCalls',
      'dialogsMap',
      'numberOfIntegrations',
      'numberOfSurveys',
      'numberOfContextVariables',
      'getMessageById',
      'getApiCallById',
      'dialogHasJump',
      'hasChildren',
      'activeChannelView'
    ]),
    ...mapGetters('integrations', ['integrations']),
    hasPreviousAlternative() {
      return !this.userMessageInEdit.prevAlternative
    },
    hasNextAlternative() {
      return !this.userMessageInEdit.nextAlternative
    },
    isMessageSelected() {
      return this.userMessageInEdit.isEditing || this.botMessageInEdit.isEditing
    },
    isCreateAlternativeDisabled() {
      return !this.userMessageInEdit.isEditing
    },
    dialogId() {
      if (this.userMessageInEdit && this.userMessageInEdit.isEditing) {
        return this.userMessageInEdit.dialogId
      }
      if (this.botMessageInEdit) {
        return this.botMessageInEdit.dialogId
      }
      return null
    },
    apiCallsBadge() {
      return (
        this.numberOfIntegrations(this.dialogId) +
        this.numberOfSurveys(this.dialogId)
      )
    },
    contextVariablesBadge() {
      return this.numberOfContextVariables(this.dialogId)
    },
    hasJump() {
      return this.dialogHasJump(this.dialogId)
    },
    dialogJump() {
      const jumpId = this.dialogHasJump(this.dialogId)
      return jumpId && this.dialogsMap && this.dialogsMap[jumpId]
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'LOAD_NEXT_ALTERNATIVE_PATH',
      'LOAD_PREV_ALTERNATIVE_PATH',
      'LOAD_ALTERNATIVE_PATH',
      'CREATE_ALTERNATIVE_PATH',
      'REMOVE_MESSAGE',
      'SET_MESSAGE_IN_EDIT',
      'RESET_USER_MESSAGE',
      'SET_DIALOG_JUMPS',
      'SET_DIALOG_SURVEYS',
      'RESET_CONDITIONS',
      'SET_CONDITIONS_IN_EDIT',
      'UPDATE_BOT_TAB_INDEX',
      'SET_RESPONSE_ACTIVE',
      'SET_ACTIVE_CHANNEL_VIEW'
    ]),
    ...mapActions('botMaker', [
      'SAVE_DIALOG',
      'DELETE_BOT_MESSAGE',
      'DELETE_USER_MESSAGE',
      'DELETE_UNSAVED_MESSAGE',
      'GET_API_CALLS',
      'UPDATE_USER_MESSAGE',
      'UPDATE_DIALOG_INTEGRATIONS',
      'GET_DIALOGS',
      'SAVE_BOT_MESSAGE'
    ]),
    navigateToDialog(node) {
      const message = this.getMessageById(node.idUserMessage)
      this.SET_CONDITIONS_IN_EDIT(message)
      this.LOAD_ALTERNATIVE_PATH(message)
      this.closeJumpPopup()
    },
    openIntegrationPopupRef() {
      if (this.$refs.integrationPopup)
        this.$refs.integrationPopup.openIntegrationsPopup()
      else
        setTimeout(() => {
          this.openIntegrationPopupRef()
        })
    },
    openIntegrationPopup(newVal) {
      this.integrationsPopupActive = newVal
    },
    toggleContextVariables() {
      this.$nextTick(() => {
        this.$refs.contextVariables.openPopup()
      })
    },
    loadPreviousAlternative() {
      this.LOAD_PREV_ALTERNATIVE_PATH(this.userMessageInEdit)
      this.SET_CONDITIONS_IN_EDIT(this.userMessageInEdit)
    },
    alertUser() {
      if (
        this.bot.noIntentAction &&
        this.bot.noIntentAction.key == 'jump' &&
        this.bot.noIntentAction.args[0] == this.userMessageInEdit.dialogId
      ) {
        this.$vs.dialog({
          type: 'confirm',
          title: this.lang.botMaker.editBot.deleteDialog.title[
            this.languageSelected
          ],
          text: this.lang.botMaker.editBot.deleteDialog.textWithJump[
            this.languageSelected
          ],
          acceptText: this.lang.botMaker.accept[this.languageSelected],
          cancelText: this.lang.botMaker.cancel[this.languageSelected],
          accept: async () => {
            this.$vs.loading()
            const getDialogsId =
              this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
            let { dialogSavedId } = this.botMessageInEdit
            if (
              !dialogSavedId &&
              this.userMessageInEdit &&
              this.userMessageInEdit.dialogSavedId
            ) {
              dialogSavedId = this.userMessageInEdit.dialogSavedId
            }
            if (this.userMessageInEdit.isSaved) {
              this.SET_RESPONSE_ACTIVE()
              this.DELETE_USER_MESSAGE(this.userMessageInEdit)
                .then(() => {
                  // .then(async () => {
                  // await this.GET_DIALOGS(undefined)
                  this.RESET_CONDITIONS()
                  this.SET_ACTIVE_CHANNEL_VIEW()
                  this.$vs.notify({
                    title: this.lang.botMaker.editBot.deleteMessage.success
                      .title[this.languageSelected],
                    text: this.lang.botMaker.editBot.deleteMessage.success.text[
                      this.languageSelected
                    ],
                    color: 'success'
                  })
                })
                .catch(() => {
                  this.$vs.notify({
                    title: this.lang.botMaker.errorTitle[this.languageSelected],
                    text: this.lang.botMaker.error[this.languageSelected],
                    color: 'danger'
                  })
                })
                .finally(() => {
                  this.$vs.loading.close()
                })
            } else {
              this.REMOVE_MESSAGE(this.userMessageInEdit)
              this.SET_MESSAGE_IN_EDIT()
              this.RESET_USER_MESSAGE()
              this.RESET_CONDITIONS()
              await this.GET_DIALOGS(dialogSavedId || getDialogsId)
              this.$vs.loading.close()
            }
          }
        })
      } else {
        this.$vs.dialog({
          type: 'confirm',
          title: this.lang.botMaker.editBot.deleteDialog.title[
            this.languageSelected
          ],
          text: this.lang.botMaker.editBot.deleteDialog.text[
            this.languageSelected
          ],
          acceptText: this.lang.botMaker.accept[this.languageSelected],
          cancelText: this.lang.botMaker.cancel[this.languageSelected],
          accept: async () => {
            this.$vs.loading()
            const getDialogsId =
              this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
            let { dialogSavedId } = this.botMessageInEdit
            if (
              !dialogSavedId &&
              this.userMessageInEdit &&
              this.userMessageInEdit.dialogSavedId
            ) {
              dialogSavedId = this.userMessageInEdit.dialogSavedId
            }
            if (this.userMessageInEdit.isSaved) {
              this.SET_RESPONSE_ACTIVE()
              this.DELETE_USER_MESSAGE(this.userMessageInEdit)
                .then(() => {
                  // .then(async () => {
                  // await this.GET_DIALOGS(undefined)
                  this.RESET_CONDITIONS()
                  this.SET_ACTIVE_CHANNEL_VIEW()
                  this.$vs.notify({
                    title: this.lang.botMaker.editBot.deleteMessage.success
                      .title[this.languageSelected],
                    text: this.lang.botMaker.editBot.deleteMessage.success.text[
                      this.languageSelected
                    ],
                    color: 'success'
                  })
                })
                .catch(() => {
                  this.$vs.notify({
                    title: this.lang.botMaker.errorTitle[this.languageSelected],
                    text: this.lang.botMaker.error[this.languageSelected],
                    color: 'danger'
                  })
                })
                .finally(() => {
                  this.$vs.loading.close()
                })
            } else {
              this.REMOVE_MESSAGE(this.userMessageInEdit)
              this.SET_MESSAGE_IN_EDIT()
              this.RESET_USER_MESSAGE()
              this.RESET_CONDITIONS()
              await this.GET_DIALOGS(dialogSavedId || getDialogsId)
              this.$vs.loading.close()
            }
          }
        })
      }
    },
    acceptDelete() {
      this.$vs.loading()
      this.DELETE_BOT_MESSAGE(this.botMessageInEdit)
        .then(() => {
          this.$vs.notify({
            title: this.lang.botMaker.editBot.deleteMessage.success.title[
              this.languageSelected
            ],
            text: this.lang.botMaker.editBot.deleteMessage.success.text[
              this.languageSelected
            ],
            color: 'success'
          })
          this.UPDATE_BOT_TAB_INDEX(0)
          this.SET_ACTIVE_CHANNEL_VIEW('all')
        })
        .catch(() => {
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    deleteMessage() {
      if (this.userMessageInEdit.isEditing) {
        this.alertUser()
      } else if (this.botMessageInEdit.isEditing) {
        if (
          // deleting default answer when multiple enabled channels will
          // delete it for all channels
          this.botMessageInEdit.type.platforms[0] === 'all' &&
          this.integrations.length > 1
        ) {
          this.$vs.dialog({
            type: 'confirm',
            color: 'warning',
            title: this.lang.surveyIntegrations.channels.whatsapp.messages
              .confirm.title[this.languageSelected],
            text: this.lang.botMaker.editBot.deleteMessage.confirm.text[
              this.languageSelected
            ],
            acceptText: this.lang.botMaker.editBot.deleteMessage.confirm.accept[
              this.languageSelected
            ],
            cancelText: this.lang.botMaker.editBot.deleteMessage.confirm.cancel[
              this.languageSelected
            ],
            buttonCancel: 'flat',
            accept: async () => this.acceptDelete()
          })
        } else {
          this.acceptDelete()
        }
      } else {
        // delete unsaved messages
        this.$vs.loading()
        this.DELETE_UNSAVED_MESSAGE().finally(() => {
          this.$vs.loading.close()
        })
      }
    },
    loadNextAlternative() {
      this.LOAD_NEXT_ALTERNATIVE_PATH(this.userMessageInEdit)
      this.SET_CONDITIONS_IN_EDIT(this.userMessageInEdit)
    },
    createAlternativePath() {
      this.SET_DIALOG_JUMPS()
      this.RESET_CONDITIONS()
      this.CREATE_ALTERNATIVE_PATH(this.userMessageInEdit)
    },
    async openJumpPopup() {
      const surveys = this.currentDialogHasSurveys
      if (surveys) {
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.errorJump[this.languageSelected],
          color: 'danger'
        })
        return
      }
      if (this.hasChildren) {
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.hasChildren[this.languageSelected],
          color: 'danger'
        })
        return
      }
      /** When opened the popup there was a but related to the version GET function
       * Changes to prevent the issue, could happen in other parts as well
       */
      await this.initTree(false, true)
      this.jumpPopupActive = true
    },
    clickNode(node) {
      this.selectedNode = node
      this.disableAddJump = node && (node[0].current || node.length === 0)
    },
    closeJumpPopup() {
      this.jumpPopupActive = false
    },
    onCloseJumps() {
      this.$refs.konaTree.reset()
    },
    getDialogToUpdate(operation = 'update') {
      let dialogIdToUpdate =
        this.userMessageInEdit.dialogId || this.botMessageInEdit.dialogId

      let dialogToUpdate = this.dialogsMap[dialogIdToUpdate]

      if (operation === 'update') {
        // update jump
        dialogToUpdate.type = 'jump'
        const jumpDialogId = this.selectedNode[0].id
        dialogToUpdate.jump = jumpDialogId
      } else {
        // delete jump
        dialogToUpdate.type = 'node'
        dialogToUpdate.jump = null
      }

      // remove tree root parent
      if (dialogToUpdate.parent === '0') {
        delete dialogToUpdate.parent
      }
      return dialogToUpdate
    },
    updateJump() {
      this.$vs.loading()

      const getDialogsId =
        this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
      let { dialogSavedId } = this.botMessageInEdit
      if (
        !dialogSavedId &&
        this.userMessageInEdit &&
        this.userMessageInEdit.dialogSavedId
      ) {
        dialogSavedId = this.userMessageInEdit.dialogSavedId
      }

      //   this.userMessageInEdit.dialogSavedId ||
      //   this.botMessageInEdit.dialogSavedId

      const dialogToUpdate = this.getDialogToUpdate('update')
      BotMakerService.updateDialog(dialogToUpdate._id, dialogToUpdate)
        .then(async () => {
          await this.GET_DIALOGS(dialogSavedId || getDialogsId)
          this.closeJumpPopup()
          this.$vs.notify({
            color: 'success',
            title: this.lang.botMaker.jump.update.success.title[
              this.languageSelected
            ],
            text: this.lang.botMaker.jump.update.success.text[
              this.languageSelected
            ]
          })
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: this.lang.botMaker.jump.update.error.title[
              this.languageSelected
            ],
            text: this.lang.botMaker.jump.update.error.text[
              this.languageSelected
            ]
          })
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    deleteJump() {
      this.$vs.loading()

      const getDialogsId =
        this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
      let { dialogSavedId } = this.botMessageInEdit
      if (
        !dialogSavedId &&
        this.userMessageInEdit &&
        this.userMessageInEdit.dialogSavedId
      ) {
        dialogSavedId = this.userMessageInEdit.dialogSavedId
      }
      //   this.userMessageInEdit.dialogSavedId ||
      //   this.botMessageInEdit.dialogSavedId

      const dialogToUpdate = this.getDialogToUpdate('delete')

      BotMakerService.updateDialog(dialogToUpdate._id, dialogToUpdate)
        .then(async () => {
          await this.GET_DIALOGS(dialogSavedId || getDialogsId)
          this.closeJumpPopup()
          this.$vs.notify({
            color: 'success',
            title: this.lang.botMaker.jump.remove.success.title[
              this.languageSelected
            ],
            text: this.lang.botMaker.jump.remove.success.text[
              this.languageSelected
            ]
          })
        })
        .catch(() => {
          this.$vs.notify({
            color: 'danger',
            title: this.lang.botMaker.jump.remove.error.title[
              this.languageSelected
            ],
            text: this.lang.botMaker.jump.remove.error.text[
              this.languageSelected
            ]
          })
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  },
  async mounted() {
    await this.GET_API_CALLS(this.bot.activeVersion)
  }
}
</script>

<style lang="scss">
.message-nav {
  display: flex;
  flex-direction: column;
  .alt-wrapper {
    position: relative;
    z-index: 1;
    .badge {
      position: absolute;
      background: red;
      z-index: 2;
      border-radius: 50px;
      padding: 2px 5px;
      font-size: 9px;
      top: -4px;
      left: -4px;
      color: white;
    }
    .right-side {
      .badge {
        top: 1px;
        left: 28px;
      }
    }
  }

  .message-nav-btn {
    margin: 5px 0;
  }
}
.con-vs-dropdown--menu {
  z-index: 99999 !important;
}

.jumpHeader {
  top: 0;
  right: 0;
  left: 0;
  position: sticky;
  background: white;
  z-index: 9999;
  padding-top: 15px;
}
.jumpFooter {
  bottom: 0;
  right: 0;
  left: 0;
  position: sticky;
  background: white;
  z-index: 9999;
  padding-bottom: 15px;
}
</style>
