import { MessageType } from '../MessageType'
import BotMakerService from '../../../services/botMaker.service'

export class ImageMessageType extends MessageType {
  constructor(name, fileData, fileUrl, fileType = 'jpeg') {
    super(name)
    this.fileData = fileData
    this.fileUrl = fileUrl
    this.fileFormat = fileType
  }

  isTextMessage() {
    return false
  }
  isRegexMessage() {
    return false
  }
  isImageMessage() {
    return true
  }

  isMediaMessage() {
    return false
  }

  isIntentMessage() {
    return false
  }

  isCardMessage() {
    return false
  }

  isSliderMessage() {
    return false
  }

  isGenericMessage() {
    return false
  }

  setMessage(file) {
    this.fileData = new FormData()
    this.fileData.append('file', file)
    this.name = this.fileData.get('file').name
    this.fileFormat = this.fileData
      .get('file')
      .name.split('.')
      .pop()
  }

  generateMessageToSave() {
    return {
      platforms: ['all'],
      responseFile: {
        url: /\/bucket/.test(this.fileUrl)
          ? `/bucket${this.fileUrl.split('/bucket')[1]}`
          : this.fileUrl,
        type: this.fileFormat
      }
    }
  }

  save(botId = undefined) {
    return BotMakerService.fileUpload(this.fileData, botId)
      .then(response => {
        this.fileUrl = response.data.url
        return response
      })
      .catch(error => {
        return error
      })
  }
}
