var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mobileContent",staticClass:"mobile-view-messages"},_vm._l((_vm.messagesByDialog),function(dialog,indexD){return _c('div',{key:indexD,staticClass:"dialog",class:{
      'has-integrations': _vm.numberOfIntegrations(dialog[0].dialogId),
      'has-jump': _vm.dialogHasJump(dialog[0].dialogId)
    }},[_c('draggable',{attrs:{"move":_vm.onMessageMoveCallBack,"options":{ handle: '.bot-message' },"group":"mobileMessage"},on:{"end":function($event){_vm.onDragMessageEnd(indexD, $event)},"start":function($event){_vm.onDragMessageStart(indexD, $event)}},model:{value:(_vm.messagesByDialog[indexD]),callback:function ($$v) {_vm.$set(_vm.messagesByDialog, indexD, $$v)},expression:"messagesByDialog[indexD]"}},_vm._l((dialog),function(msg,index){return _c('div',{key:index,staticClass:"mobile-message"},[(_vm.isVisibleForActivePlatform(msg, dialog))?_c('div',{staticClass:"flex flex-column items-start mobile-container",class:{
            'flex-row-reverse': msg.isUser === 0 || msg.isUserMessage()
          }},[(_vm.showSliderMessage(msg))?_c('div',{staticClass:"arrows-msg",on:{"click":function($event){_vm.back(msg)}}},[_c('span',{staticClass:"arrow-back material-icons"},[_vm._v("arrow_back_ios")])]):_vm._e(),_vm._v(" "),(
              _vm.numberOfIntegrations(dialog[0].dialogId) &&
                msg.type.beforeAPICall === false &&
                _vm.idOfFirstBeforeAPICall(dialog) === msg.id
            )?_c('div',{staticClass:"api-call"},[(index !== 1)?_c('feather-icon',{attrs:{"icon":"ChevronUpIcon","svgClasses":"h-4 w-4"},on:{"click":function($event){_vm.moveApiCall(indexD, index - 1)}}}):_vm._e(),_vm._v(" "),_c('vs-divider',{attrs:{"icon":"icon-zap","icon-pack":"feather"}}),_vm._v(" "),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"},on:{"click":function($event){_vm.moveApiCall(indexD, index + 1)}}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg",class:{
              'text-white user-message':
                msg.isUser === 0 || msg.isUserMessage(),
              active: msg.isEditing || !msg.isSaved,
              'bot-message': !msg.isUserMessage()
            },attrs:{"id":'message__' + msg.id},on:{"click":function($event){_vm.toEditor(msg)}}},[_c('span',[(_vm.showTextMessage(msg))?_c('div',{staticClass:"text"},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.messageName(msg)),expression:"messageName(msg)"}]})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"message-icons"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasBotResponse(msg)),expression:"!hasBotResponse(msg)"}],staticClass:"missing-bot-response"},[_c('vx-tooltip',{attrs:{"text":_vm.lang.botMaker.botEditor.mobileView.emptyBotResponses[
                      _vm.languageSelected
                    ]}},[_c('feather-icon',{attrs:{"icon":"AlertTriangleIcon","svgClasses":"mobile-view-message-icon"}})],1)],1),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAlternativePaths(msg)),expression:"hasAlternativePaths(msg)"}]},[_c('vx-tooltip',{attrs:{"text":_vm.lang.botMaker.botEditor.mobileView.hasAlternativePath[
                      _vm.languageSelected
                    ]}},[_c('feather-icon',{attrs:{"icon":"GitBranchIcon","svgClasses":"mobile-view-message-icon"}})],1)],1)]),_vm._v(" "),(_vm.showImageMessage(msg))?_c('img',{staticClass:"img-message",attrs:{"src":msg.type.fileUrl,"alt":"bot-image"}}):_vm._e(),_vm._v(" "),(_vm.showMediaMessage(msg))?_c('MediaPreview',{attrs:{"savedFile":msg.type,"frame":false}}):_vm._e(),_vm._v(" "),(_vm.showButtonsMessage(msg))?_c('ButtonsPreview',{attrs:{"isLast":_vm.isLast(index, dialog.length),"msg":msg}}):_vm._e(),_vm._v(" "),(_vm.showCardMessage(msg))?_c('CardPreview',{attrs:{"msg":msg}}):_vm._e()],1),_vm._v(" "),(
              _vm.numberOfIntegrations(dialog[0].dialogId) &&
                msg.type.beforeAPICall === true &&
                index === dialog.length - 1
            )?_c('div',{staticClass:"api-call"},[_c('feather-icon',{attrs:{"icon":"ChevronUpIcon","svgClasses":"h-4 w-4"},on:{"click":function($event){_vm.moveApiCall(indexD, index)}}}),_vm._v(" "),_c('vs-divider',{attrs:{"icon":"icon-zap","icon-pack":"feather"}}),_vm._v(" "),(index !== dialog.length - 1)?_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"},on:{"click":function($event){_vm.moveApiCall(indexD, index + 1)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showSliderMessage(msg))?_c('div',{staticClass:"arrows-msg",on:{"click":function($event){_vm.forward(msg)}}},[_c('span',{staticClass:"arrow-forward material-icons"},[_vm._v("arrow_forward_ios")])]):_vm._e()]):_vm._e()])})),_vm._v(" "),(
        !_vm.dialogHasBotResponse(dialog) &&
          _vm.numberOfIntegrations(dialog[0].dialogId)
      )?_c('div',{staticClass:"mobile-message"},[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg",on:{"click":function($event){_vm.toEditor()}}},[_c('span',{staticClass:"ellipsis",attrs:{"title":_vm.getApiCallUrl(dialog)}},[_vm._v("\n            "+_vm._s(_vm.getApiCallUrl(dialog))+"\n          ")]),_vm._v(" "),_c('div',{staticClass:"message-icons"},[(_vm.numberOfIntegrations(dialog[0].dialogId))?_c('feather-icon',{attrs:{"icon":"ZapIcon","svgClasses":"mobile-view-message-icon"}}):_vm._e()],1)])])]):_vm._e(),_vm._v(" "),(_vm.dialogHasSurvey(dialog[0].dialogId))?_c('vs-divider',{attrs:{"icon":"icon-send","icon-pack":"feather"}}):_vm._e(),_vm._v(" "),(_vm.dialogHasJump(dialog[0].dialogId))?_c('vs-divider',{attrs:{"icon":"icon-corner-up-right","icon-pack":"feather"}}):_vm._e(),_vm._v(" "),(_vm.dialogHasValidation(dialog[0].dialogId))?_c('vs-divider',{attrs:{"icon":"check_circle_outline"}}):_vm._e()],1)}))}
var staticRenderFns = []

export { render, staticRenderFns }