<template class="user-says" id="user-says">
  <div>
    <h3 class="text-center">
      {{ lang.botMaker.botEditor.botSays.title[languageSelected] }}
    </h3>
    <br />

    <!-- {{ botMessageInEdit }} -->
    <!-- <vs-tabs alignment="center" v-model="activeTab">
        <template v-for="tab in tabsModel">
          <vs-tab
            :key="tab.type"
            :vsLabel="tab.label"
            :vsIcon="tab.icon"
            @click="switchToMessageType(tab.type)"
            v-if="tab.isVisible"
          >
            <component :is="tab.component" />
          </vs-tab>
        </template>
    </vs-tabs>-->

    <KonaTabs
      :returnSelectedTab="true"
      @selectedTab="setupSelected"
      @onClick="switchToMessageType"
      :tabsStyle="tabsStyle"
      :orderById="true"
    >
      <template v-for="tab in tabsModel">
        <KonaTab
          :id="tab.id"
          :key="tab.id"
          :name="tab.label"
          :type="tab.type"
          :icon="tab.icon"
          :infoTooltip="tab.infoTooltip"
          :selected="isTabSelected(tab)"
          v-if="tab.isVisible"
        >
          <vx-card>
            <div class="tab-card">
              <KonaHelpTooltip
                :text="
                  lang.helpTooltips.botMaker.defaultResponse[languageSelected]
                "
                position="right"
              />
              <KonaAlert
                v-show="tab.infoTooltip"
                icon="InfoIcon"
                bg-color="rgba(var(--vs-warning), 0.15)"
                color="rgba(var(--vs-warning), 1)"
                >{{ tab.infoTooltip }}</KonaAlert
              >
              <component :is="tab.component" :read-only="tab.readOnly"/>
            </div>
          </vx-card>
        </KonaTab>
      </template>
    </KonaTabs>

    <ChannelViewSelector />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
// import AudioMessage from './botSays/AudioMessage'
import { TextMessageType } from '../../../../../models/Messages/MessageTypes/TextMessageType'
import { ImageMessageType } from '../../../../../models/Messages/MessageTypes/ImageMessageType'
import { MediaMessageType } from '../../../../../models/Messages/MessageTypes/MediaMessageType'
import { ButtonsMessageType } from '../../../../../models/Messages/MessageTypes/ButtonsMessageType'
import { CardMessageType } from '../../../../../models/Messages/MessageTypes/CardMessageType'
import { SliderMessageType } from '../../../../../models/Messages/MessageTypes/SliderMessageType'
import { MAX_FILE_SIZE } from '@/constants/constants'
import { MESSAGE_TYPES_CHANNELS } from '@/constants/constants'

export default {
  components: {
    TextMessage: () => import('./botSays/TextMessage'),
    // ImageMessage: () => import('./botSays/ImageMessage'),
    MediaMessage: () => import('./botSays/MediaMessage'),
    ButtonsMessage: () => import('./botSays/ButtonsMessage'),
    QuickReplyMessage: () => import('./botSays/QuickReplyMessage'),
    RcsSlider: () => import('./botSays/RcsSlider.vue'),
    RcsCard: () => import('./botSays/RcsCard.vue'),
    ChannelViewSelector: () =>
      import('./botSays/channelViewSelector/ChannelViewSelector.vue'),
    // AudioMessage
    KonaTabs: () => import('../../../../../components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('../../../../../components/kona-tabs/KonaTab.vue'),
    KonaHelpTooltip: () => import('@/components/KonaHelpTooltip.vue'),
    KonaAlert: () => import('../../../../../components/KonaAlert.vue')
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tabsModel: [],
      selectedTab: '',
      RcsItems: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botMessageInEdit',
      'botMessageActiveTab',
      'activeBotTab',
      'activeChannelView',
      'selectedResponse',
      'dialogsMap',
      'changedMessageInEdit',
      'responseValidations',
      'activeVersion',
      'bot'
    ]),
    selectedTabType() {
      return this.selectedTab.type
    },
    currentTab() {
      return this.botMessageInEdit && this.botMessageInEdit.type.getType()
    },
    changeTab() {
      const tabChanged = [
        this.currentTab,
        this.changedMessageInEdit,
        this.activeChannelView
      ].join(' ')
      return tabChanged
      //return `${this.currentTab}${this.changedMessageInEdit}${this.activeChannelView}`
    },
    ...mapGetters('integrations', ['integrations']),
    activeChannels() {
      // return this.integrations.filter(i => i.active)
      return this.integrations
        .filter(i => i.active)
        .map(i => {
          i.type = i.type === 'web' ? 'all' : i.type
          return i
        })
    },

    // activeTab: {
    //   get() {
    //     return this.activeBotTab
    //   },
    //   set(index) {
    //     this.UPDATE_BOT_TAB_INDEX(index)
    //   }
    // },
    tabsStyle() {
      return `
        background: white;
        height: 50px;
        border: 3px solid transparent;
        margin-top: 0;
        border-bottom: 0;
        align-self: flex-start;
        width: 100%;
      `
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'UPDATE_BOT_TAB_INDEX',
      'RESET_BOT_MESSAGE',
      'SET_MESSAGE_IN_EDIT'
    ]),
    ...mapActions('botMaker', ['GET_RESPONSE_VALIDATIONS']),
    ...mapActions('integrations', ['GET_INTEGRATIONS', 'CREATE_INTEGRATION']),
    setupSelected(tabType) {
      this.selectedTab = this.getTabByType(tabType)
      console.log('SETUP SELECTED::', this.selectedTab)
    },
    isSupportedPlatform(type) {
      const result = MESSAGE_TYPES_CHANNELS[type].includes(
        this.activeChannelView
      )
      return result
    },
    switchToMessageType(tabIndex) {
      this.RESET_BOT_MESSAGE()
      this.SET_MESSAGE_IN_EDIT()

      this.selectedTab = this.tabsModel[tabIndex]
      const messageType = this.selectedTab.type

      this.createNewResponse(messageType)
    },
    // eslint-disable-next-line no-unused-vars
    setTabVisibility(tab, activeChannel, tabIndex) {
      const isVisible = MESSAGE_TYPES_CHANNELS[tab.type].includes(activeChannel)
      tab.isVisible = isVisible
    },
    isTabSelected(tab) {
      return !!(this.selectedTab && this.selectedTab.id === tab.id)
    },
    getTabByType(type) {
      return (
        this.tabsModel.find(el => {
          return el.type == type
        }) || this.tabsModel[0]
      )
    },
    createNewResponse(messageType, message = null) {
      const defaultText = (message && message.name) || ''
      const platforms = [this.activeChannelView]
      const defaultButtons = (message && message.responseOptions) || []
      const defaultCard = (message && message.responseCard) || []
      const id = this.selectedResponse ? this.selectedResponse._id : null

      console.log('MESSAGE TYPE TEXT::', messageType)

      switch (messageType) {
        case 'text':
          this.botMessageInEdit.type = new TextMessageType(
            defaultText,
            platforms,
            id
          )
          break
        case 'media':
          this.botMessageInEdit.type = new MediaMessageType(
            defaultText,
            '',
            '',
            '',
            platforms,
            id
          )
          break
        case 'buttons':
          this.botMessageInEdit.type = new ButtonsMessageType(
            defaultText,
            defaultButtons,
            platforms,
            id
          )
          break
        case 'image':
          this.botMessageInEdit.type = new ImageMessageType()
          break
        case 'card':
          this.botMessageInEdit.type = new CardMessageType(
            defaultText,
            defaultCard,
            platforms,
            id
          )
          break
        case 'slider':
          this.botMessageInEdit.type = new SliderMessageType(
            defaultText,
            platforms,
            id
          )
          break
        default:
          this.botMessageInEdit.type = new TextMessageType(
            defaultText,
            platforms,
            id
          )
          break
      }
    },
    async createDefaultIntegrations() {
      const hasWebIntegration = this.integrations.find(
        i => i.type === 'all' || i.type === 'web'
      )
      if (!hasWebIntegration) {
        try {
          const payload = {
            service: this.$route.query.serviceId,
            type: 'web',
            active: true,
            config: {}
          }
          await this.CREATE_INTEGRATION(payload)
          //TODO: update integrations without calling service
          const serviceId = this.$route.query.serviceId

          const payloadIntegrations = {
            services: [serviceId]
          }

          await this.GET_INTEGRATIONS(payloadIntegrations)
        } catch (error) {
          console.error(error)
        }
      }
    },
    async updateView(botMessagePlatform) {
      console.log('UPDATE VIEW')
      // set tabs visibility
      this.tabsModel.forEach((tab, index) => {
        this.setTabVisibility(tab, botMessagePlatform, index)
      })
      if (this.botMessageInEdit.isEditing) {
        // search tab by message type
        const tab = this.tabsModel.filter(
          t =>
            t.type === this.botMessageInEdit.type.getType() && t.isVisible
        )
        await this.$nextTick()
        // select active tab

        if (tab.length) {
          this.selectedTab = tab[0]
          console.log('UPDATE TAG VIEW 1::', this.selectedTab)
          if (
            this.botMessageInEdit.type.platforms &&
            this.botMessageInEdit.type.platforms[0] &&
            this.botMessageInEdit.type.platforms[0] !== this.activeChannelView
          ) {
            const respForPlatform = this.getResponsesForActivePlatform()

            // si no existe un mensaje con el mismo responseId y platform activeChannelView
            if (!respForPlatform.length) {
              this.createNewResponse(
                this.selectedTab.type,
                this.botMessageInEdit.type
              )
            } else {
              // si no seleccionarlo
            }
          }
        } else {
          this.selectedTab = this.tabsModel.filter(t => t.isVisible)[0]
          console.log('UPDATE TAG VIEW 2::', this.selectedTab)
          const respForPlatform = this.getResponsesForActivePlatform()

          // si no existe un mensaje con el mismo responseId y platform activeChannelView
          if (!respForPlatform.length) {
            this.createNewResponse(
              this.selectedTab.type,
              this.botMessageInEdit.type
            )
          } else {
            // si no seleccionarlo
          }
        }
      } else {
        this.selectedTab = this.tabsModel.filter(tab => tab.isVisible)[0]
        console.log('UPDATE TAG VIEW 3::', this.selectedTab)
        this.createNewResponse(this.selectedTab.type)
      }
    },
    getResponsesForActivePlatform() {
      return this.dialogsMap[this.botMessageInEdit.dialogId].responses.filter(
        resp =>
          resp.responseId === this.botMessageInEdit.type.responseId &&
          resp.platforms[0] === this.activeChannelView
      )
    }
  },
  watch: {
    changeTab(newVal) {
      console.log('TAB CHANGED::', newVal)
      let newTab = this.currentTab
      const isSupported = this.isSupportedPlatform(newTab)
      if (!isSupported) newTab = 'text'
      const el = this.tabsModel.find(e => {
        return e.type == newTab
      })
      if (el != undefined) {
        this.selectedTab = el
        console.log('UPDATE TAG CHANGED::', this.selectedTab)
      }
    },
    activeChannelView(currentChannel) {
      // SET TABS PER CHANNEL
      this.tabsModel.map(tab => {
        const isVisible = MESSAGE_TYPES_CHANNELS[tab.type].includes(
          currentChannel
        )
        // En el caso que no soporte el tab activo
        // if (isVisible == false) {
        //   setTimeout(() => {
        //     // Horrible solución, pero funciona TODO: refactor
        //     jQuery('.navigation-tab__item')
        //       .eq(0)
        //       .click()
        //   }, 100)
        // }
        tab.isVisible = isVisible
      })
    }
    // botMessageInEdit(bot) {
    // if (bot.isSaved) {
    //   this.updateView(bot.type.platforms[0])
    // } else {
    //   this.updateView('all')
    // }
    // }
  },
  created() {
    this.tabsModel = [
      {
        id: 0,
        type: 'text',
        label: this.lang.botMaker.botEditor.messageType.text.title[
          this.languageSelected
        ],
        icon: 'text_format',
        component: 'TextMessage',
        isVisible: true,
        readOnly: this.readOnly
      },

      {
        id: 1,
        type: 'media',
        label: this.lang.botMaker.botEditor.messageType.media.title[
          this.languageSelected
        ],
        icon: 'image',
        component: 'MediaMessage',
        isVisible: true,
        readOnly: this.readOnly,
        infoTooltip: this.lang.helpTooltips.botMaker.imageTab[
          this.languageSelected
        ].replace('%s', MAX_FILE_SIZE / 1024 / 1024 + ' MB.')
      },
      {
        id: 2,
        type: 'buttons',
        label: this.lang.botMaker.botEditor.messageType.buttons.title[
          this.languageSelected
        ],
        icon: 'list',
        component: 'ButtonsMessage',
        isVisible: true,
        readOnly: this.readOnly,
        infoTooltip: this.lang.helpTooltips.botMaker.cardsTab[
          this.languageSelected
        ]
      },
      {
        id: 3,
        type: 'card',
        label: this.lang.botMaker.botEditor.messageType.RcsCard.title[
          this.languageSelected
        ],
        icon: 'sms',
        component: 'RcsCard',
        isVisible: true,
        readOnly: this.readOnly,
        infoTooltip: this.lang.helpTooltips.botMaker.cardsTab[
          this.languageSelected
        ]
      },
      {
        id: 4,
        type: 'slider',
        label: this.lang.botMaker.botEditor.messageType.RcsSlider.title[
          this.languageSelected
        ],
        icon: 'view_carousel',
        component: 'RcsSlider',
        isVisible: true,
        readOnly: this.readOnly,
        infoTooltip: this.lang.helpTooltips.botMaker.cardsTab[
          this.languageSelected
        ]
      }
    ]
    this.selectedTab = this.tabsModel[0]
    console.log('UPDATE TAG CREATED::', this.selectedTab)
  },
  async mounted() {
    const serviceId = this.$route.query.serviceId

    if (serviceId) {
      // editing bot -> update channels states
      const payload = {
        services: [serviceId]
      }

      this.$vs.loading()
      try {
        await this.GET_INTEGRATIONS(payload)
        await this.createDefaultIntegrations()
      } catch (error) {
        console.warn(error)
      } finally {
        this.$vs.loading.close()
      }
    }

    this.GET_RESPONSE_VALIDATIONS(this.bot.id)
  }
}
</script>

<style lang="scss">
.user-says {
  .left-align {
    text-align: left;
  }
}
.vx-card .vx-card__collapsible-content .vx-card__body {
  // padding: 30px;

  .tab-card {
    position: relative;

    #kona-help-tooltip {
      position: absolute;
      right: -25px;
      top: -25px;
    }
  }
}
.kona-alert {
  font-size: 12px !important;
  margin-top: 0 !important;
}
</style>
