<template>
  <div class="mobile-view-messages" ref="mobileContent">
    <!-- dialogs -->
    <div
      v-for="(dialog, indexD) in messagesByDialog"
      :key="indexD"
      class="dialog"
      :class="{
        'has-integrations': numberOfIntegrations(dialog[0].dialogId),
        'has-jump': dialogHasJump(dialog[0].dialogId)
      }"
    >
      <!-- messages -->
      <draggable
        @end="onDragMessageEnd(indexD, $event)"
        :move="onMessageMoveCallBack"
        :options="{ handle: '.bot-message' }"
        v-model="messagesByDialog[indexD]"
        group="mobileMessage"
        @start="onDragMessageStart(indexD, $event)"
      >
        <div class="mobile-message" v-for="(msg, index) in dialog" :key="index">
          <div
            class="flex flex-column items-start mobile-container"
            :class="{
              'flex-row-reverse': msg.isUser === 0 || msg.isUserMessage()
            }"
            v-if="isVisibleForActivePlatform(msg, dialog)"
          >
            <!-- slider back arrow -->
            <div
              @click="back(msg)"
              v-if="showSliderMessage(msg)"
              class="arrows-msg"
            >
              <span class="arrow-back material-icons">arrow_back_ios</span>
            </div>

            <!-- mobile api call -->
            <div
              v-if="
                numberOfIntegrations(dialog[0].dialogId) &&
                  msg.type.beforeAPICall === false &&
                  idOfFirstBeforeAPICall(dialog) === msg.id
              "
              class="api-call"
            >
              <feather-icon
                icon="ChevronUpIcon"
                svgClasses="h-4 w-4"
                @click="moveApiCall(indexD, index - 1)"
                v-if="index !== 1"
              />
              <vs-divider icon="icon-zap" icon-pack="feather" />
              <feather-icon
                icon="ChevronDownIcon"
                svgClasses="h-4 w-4"
                @click="moveApiCall(indexD, index + 1)"
              />
            </div>

            <!-- message -->
            <div
              :id="'message__' + msg.id"
              class="msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg"
              :class="{
                'text-white user-message':
                  msg.isUser === 0 || msg.isUserMessage(),
                active: msg.isEditing || !msg.isSaved,
                'bot-message': !msg.isUserMessage()
              }"
              @click="toEditor(msg)"
            >
              <!-- text message -->
              <span>
                <div class="text" v-if="showTextMessage(msg)">
                  <span v-dompurify-html="messageName(msg)"></span>
                </div>
              </span>

              <!-- icons -->
              <div class="message-icons">
                <div class="missing-bot-response" v-show="!hasBotResponse(msg)">
                  <vx-tooltip
                    :text="
                      lang.botMaker.botEditor.mobileView.emptyBotResponses[
                        languageSelected
                      ]
                    "
                  >
                    <feather-icon
                      icon="AlertTriangleIcon"
                      svgClasses="mobile-view-message-icon"
                    />
                  </vx-tooltip>
                </div>
                <span v-show="hasAlternativePaths(msg)">
                  <vx-tooltip
                    :text="
                      lang.botMaker.botEditor.mobileView.hasAlternativePath[
                        languageSelected
                      ]
                    "
                  >
                    <feather-icon
                      icon="GitBranchIcon"
                      svgClasses="mobile-view-message-icon"
                    />
                  </vx-tooltip>
                </span>
              </div>

              <!-- image message -->
              <img
                v-if="showImageMessage(msg)"
                :src="msg.type.fileUrl"
                class="img-message"
                alt="bot-image"
              />

              <!-- media message -->
              <MediaPreview
                v-if="showMediaMessage(msg)"
                :savedFile="msg.type"
                :frame="false"
              />

              <!-- buttons message -->
              <ButtonsPreview
                v-if="showButtonsMessage(msg)"
                :isLast="isLast(index, dialog.length)"
                :msg="msg"
              />

              <!-- card message -->
              <CardPreview v-if="showCardMessage(msg)" :msg="msg" />
            </div>

            <!-- mobile api call -->
            <div
              v-if="
                numberOfIntegrations(dialog[0].dialogId) &&
                  msg.type.beforeAPICall === true &&
                  index === dialog.length - 1
              "
              class="api-call"
            >
              <feather-icon
                icon="ChevronUpIcon"
                svgClasses="h-4 w-4"
                @click="moveApiCall(indexD, index)"
              />
              <vs-divider icon="icon-zap" icon-pack="feather" />
              <feather-icon
                icon="ChevronDownIcon"
                svgClasses="h-4 w-4"
                @click="moveApiCall(indexD, index + 1)"
                v-if="index !== dialog.length - 1"
              />
            </div>

            <!-- slider next arrow -->
            <div
              @click="forward(msg)"
              v-if="showSliderMessage(msg)"
              class="arrows-msg"
            >
              <span class="arrow-forward material-icons"
                >arrow_forward_ios</span
              >
            </div>
          </div>
        </div>
      </draggable>

      <!-- integrations -->
      <!-- <div
        v-if="dialogHasBotResponse(dialog)"
        class="integrations-icon with-bot-message integration-icon"
      >
        <feather-icon
          icon="ZapIcon"
          svgClasses="mobile-view-message-icon"
          v-if="numberOfIntegrations(dialog[0].dialogId)"
        />
      </div> -->

      <!-- only user msg api call url  -->
      <div
        v-if="
          !dialogHasBotResponse(dialog) &&
            numberOfIntegrations(dialog[0].dialogId)
        "
        class="mobile-message"
      >
        <div class="flex items-start">
          <div
            class="msg break-words relative shadow-md rounded py-3 px-4 mb-2 rounded-lg"
            @click="toEditor()"
          >
            <span class="ellipsis" :title="getApiCallUrl(dialog)">
              {{ getApiCallUrl(dialog) }}
            </span>
            <div class="message-icons">
              <feather-icon
                icon="ZapIcon"
                svgClasses="mobile-view-message-icon"
                v-if="numberOfIntegrations(dialog[0].dialogId)"
              />
            </div>
          </div>
        </div>
      </div>

      <vs-divider
        v-if="dialogHasSurvey(dialog[0].dialogId)"
        icon="icon-send"
        icon-pack="feather"
      />

      <!-- TODO: join icons -->
      <!-- jump -->
      <vs-divider
        v-if="dialogHasJump(dialog[0].dialogId)"
        icon="icon-corner-up-right"
        icon-pack="feather"
      />

      <!-- validation -->
      <vs-divider
        v-if="dialogHasValidation(dialog[0].dialogId)"
        icon="check_circle_outline"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'
import { MESSAGE_TYPES_CHANNELS } from '../../../../../../constants/constants'
import BotMakerService from '../../../../../../services/botMaker.service'
import draggable from 'vuedraggable'
import Helper from '../../../../../../store/Helper'

export default {
  data() {
    return {
      mobileMessageArr: [],
      drag: false,
      messageByDialogBack: null,
      messagesByDialog: {}
    }
  },
  components: {
    draggable,
    MediaPreview: () => import('@/components/MediaPreview.vue'),
    ButtonsPreview: () => import('@/components/ButtonsPreview.vue'),
    CardPreview: () => import('@/components/CardPreview.vue')
    // SliderPreview: () => import('@/components/SliderPreview.vue')
    // Message: () => import('../botSays/buttonsMessage/Message.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botMakerMessages',
      'numberOfIntegrations',
      'getApiCallById',
      'integrationsByDialogId',
      'dialogHasJump',
      'dialogHasSurvey',
      'dialogsMap',
      'messages',
      'userMessageInEdit',
      'botMessageInEdit',
      'activeChannelView',
      'bot'
    ]),
    idOfFirstBeforeAPICall: () => dialog => {
      const indexOfFirst = dialog.findIndex(
        msg => msg.type.beforeAPICall === false
      )
      if (indexOfFirst > -1) return dialog[indexOfFirst].id
    },
    getApiCallUrl() {
      return dialog => {
        const apiId = this.integrationsByDialogId(dialog[0].dialogId)
        return this.getApiCallById(apiId) && this.getApiCallById(apiId).url
      }
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'SET_MESSAGE_IN_EDIT',
      'RESET_USER_MESSAGE',
      'SET_DIALOG_JUMPS',
      'SET_DIALOG_SURVEYS',
      'SET_DIALOG_VALIDATION',
      'SET_CONDITIONS_IN_EDIT',
      'SET_TAGS_IN_EDIT',
      'RESET_BOT_MESSAGE',
      'RESET_CONDITIONS',
      'SET_ACTIVE_CHANNEL_VIEW',
      'SET_RESPONSE_ACTIVE',
      'SLIDER_CHANGE',
      'SET_MESSAGE_IN_EDIT',
      'RESET_BOT_MESSAGE_VALIDATION',
      'SET_VALIDATION_STATUS'
    ]),
    ...mapActions('botMaker', ['GET_DIALOGS']),
    moveApiCall(dialogId, newIndex) {
      const apiCallIndex = newIndex - 1
      const dialog = this.dialogsMap[dialogId]
      let dialogCopy = {}
      Object.assign(dialogCopy, dialog)
      dialogCopy.responses.forEach(
        (response, index) =>
          (response.beforeAPICall = index < apiCallIndex ? true : false)
      )
      this.updateDialog(dialogCopy, dialogId)
    },
    isLast(index, dialogLength) {
      return index === dialogLength - 1
    },
    errorTextBelowButtons() {
      this.$vs.notify({
        color: 'danger',
        title: this.lang.botMaker.jump.update.error.title[
          this.languageSelected
        ],
        text: this.lang.botMaker.botEditor.errorButtons[this.languageSelected]
      })
    },
    updateDialog(dialogToUpdate, dialogIndex) {
      this.$vs.loading({
        container: this.$refs.mobileContent.offsetParent,
        scale: 0.5
      })
      if (dialogToUpdate.parent == '0') {
        dialogToUpdate.parent = null
      }
      const dialogSavedId =
        (this.userMessageInEdit && this.userMessageInEdit.dialogSavedId) ||
        (this.botMessageInEdit && this.botMessageInEdit.dialogSavedId)
      BotMakerService.updateDialog(dialogToUpdate._id, dialogToUpdate)
        .then(async () => {
          await this.GET_DIALOGS(
            dialogSavedId || this.botMakerMessages[0].dialogSavedId
          )
          this.RESET_BOT_MESSAGE()
          // this.$vs.notify({
          //   color: 'success',
          //   title: this.lang.botMaker.move.success.title[this.languageSelected],
          //   text: this.lang.botMaker.move.success.text[this.languageSelected]
          // })
        })
        .catch(e => {
          console.error(e)
          this.$vs.notify({
            color: 'danger',
            title: this.lang.botMaker.move.error.title[this.languageSelected],
            text: this.lang.botMaker.move.error.text[this.languageSelected]
          })
          this.revertDragMessage(dialogIndex)
        })
        .finally(() => {
          this.$vs.loading.close(this.$refs.mobileContent.offsetParent)
        })
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1
        while (k--) {
          arr.push(undefined)
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
      return arr // for testing
    },
    onDragMessageStart(dialogIndex) {
      this.messageByDialogBack = [...this.messagesByDialog[dialogIndex]]
      this.drag = true
    },
    revertDragMessage(dialogIndex) {
      this.messagesByDialog[dialogIndex] = [...this.messageByDialogBack]
    },
    onDragMessageEnd(dialogIndex, event) {
      /** Check if there was any position change */
      if (event.newIndex === event.oldIndex) return
      let messageByDialog = [...this.messagesByDialog[dialogIndex]]
      messageByDialog.shift()
      if (messageByDialog && messageByDialog.length) {
        const fElement = messageByDialog[0]
        this.drag = false
        const dialogId = fElement.dialogId
        const dialog = this.dialogsMap[dialogId]
        const indexOfAPICall = dialog.responses.findIndex(
          r => r.beforeAPICall === false
        )
        /** Copy Dialog and move position according */
        let dialogCopy = {}
        Object.assign(dialogCopy, dialog)
        /** Get Json to get dialog message indexes */
        let jsonDialog = {}
        dialogCopy.responses.forEach(function(el) {
          jsonDialog[el._id] = el
        })
        if (Object.keys(jsonDialog).length === messageByDialog.length) {
          dialogCopy.responses = messageByDialog
            .map((r, index) => {
              const d = jsonDialog[r.type._id]
              d.beforeAPICall = index < indexOfAPICall ? true : false
              return d
            })
            .filter(el => el !== undefined)
          /** Verify it still has the same length */
          if (dialogCopy.responses.length === dialog.responses.length) {
            this.updateDialog(dialogCopy, dialogIndex)
          }
        }
      }
    },
    onMessageMoveCallBack({ draggedContext }) {
      const { element, futureIndex, index } = draggedContext
      const isBotMessage = !element.isUserMessage()
      if (isBotMessage && index !== 0 && futureIndex === 0) {
        return false
      }
    },
    back(msg) {
      this.SLIDER_CHANGE({
        forward: false,
        msg_id: msg.id
      })
    },
    forward(msg) {
      this.SLIDER_CHANGE({
        forward: true,
        msg_id: msg.id
      })
    },
    showTextMessage(msg) {
      return (
        !msg.type.isMediaMessage() &&
        !msg.type.isButtonsMessage() &&
        !msg.type.isCardMessage() &&
        !msg.type.isSliderMessage()
      )
    },
    showImageMessage(msg) {
      return msg.type.isImageMessage() && msg.type.fileUrl
    },
    showMediaMessage(msg) {
      return msg.type.isMediaMessage() && msg.type.fileUrl
    },
    showButtonsMessage(msg) {
      return msg.type.isButtonsMessage()
    },
    showCardMessage(msg) {
      return msg.type.isCardMessage()
    },
    isSupportedBtnPlatform(msg) {
      const result = MESSAGE_TYPES_CHANNELS[msg.type.getType()].includes(
        this.activeChannelView
      )
      return result
    },
    showSliderMessage(msg) {
      return msg.type.getType() == 'slider' && this.isSupportedBtnPlatform(msg)
    },
    isImageMessage(msg) {
      // return msg.type === 'image'
      return msg.type.isImageMessage()
    },
    isVisibleForActivePlatform(msg, dialog) {
      // Agrego los gialogos para verificar el cambio de estado
      // msg.bulkDialogs = []

      if (msg.type.platforms && msg.type.responseId) {
        // const msgPlatform = msg.type.platforms[0] == 'web' ? 'all' : msg.type.platforms[0]
        const msgPlatform = msg.type.platforms

        // CONVERT WEB IN ALL
        msgPlatform.map((item, i) => {
          if (item == 'web') {
            msgPlatform[i] = 'all'
          }
        })

        if (msgPlatform.includes(this.activeChannelView)) {
          msg.isVisibleMob = true
          return true
        } else if (msgPlatform.includes('all')) {
          const sameResponseId = dialog.filter(
            m =>
              m.type.responseId === msg.type.responseId &&
              m.type.platforms &&
              m.type.platforms.length &&
              m.type.platforms.includes(this.activeChannelView) &&
              (!m.type.platforms.includes('all') ||
                !m.type.platforms.includes('web'))
          )

          // no hay otro con mismo responseID
          // y el tipo de mensaje es soportado por la plataforma
          msg.isVisibleMob = !sameResponseId.length

          return !sameResponseId.length
          // &&
          // MESSAGE_TYPES_CHANNELS[msg.type.getType()].includes(
          //   this.activeChannelView
          // )
        }
        msg.isVisibleMob = false
        return false
      }
      msg.isVisibleMob = true
      return true
    },
    resetUserBox(platform) {
      this.SET_MESSAGE_IN_EDIT()
      this.RESET_USER_MESSAGE()
      this.RESET_CONDITIONS()
      this.RESET_BOT_MESSAGE()
      this.RESET_BOT_MESSAGE_VALIDATION()
      this.SET_VALIDATION_STATUS(false)
      this.SET_ACTIVE_CHANNEL_VIEW(platform)
    },
    resetBotBox() {
      this.SET_MESSAGE_IN_EDIT()
      this.RESET_BOT_MESSAGE()
      this.RESET_BOT_MESSAGE_VALIDATION()
      this.SET_VALIDATION_STATUS(false)
      this.SET_ACTIVE_CHANNEL_VIEW()
      this.SET_RESPONSE_ACTIVE()
    },
    toEditor(msg) {
      if (!msg) return // if it is a integration message
      const msgId = msg.id
      // const msgIdHandler = msg.type.responseId
      const isSaved = msg.isSaved

      const platform = !msg.type.platforms ? 'all' : msg.type.platforms[0]

      if (msg.isUserMessage()) {
        Helper.countRelatives(this.messages, this.messages.find(m => m.id == msg.id))
        if (
          this.userMessageInEdit.id === msgId ||
          this.activeChannelView !== 'all'
        ) {
          // unselect selected user message
          this.resetUserBox(platform)
          return
        }
      }

      //unselect selected bot message
      if (this.botMessageInEdit.id === msgId) {
        this.resetBotBox()
        return
      }
      // allow message selection only for existing messages (not new)
      if (isSaved) {
        if (msgId) {
          this.SET_MESSAGE_IN_EDIT(msgId)
          if (this.userMessageInEdit.isEditing) {
            this.SET_CONDITIONS_IN_EDIT(this.userMessageInEdit)
            this.SET_TAGS_IN_EDIT(this.userMessageInEdit)
          }
          if (this.botMessageInEdit.isEditing) {
            // const platform =
            //   (this.botMessageInEdit.type &&
            //     this.botMessageInEdit.type.platforms &&
            //     this.botMessageInEdit.type.platforms[0]) ||
            //   'all'
            this.SET_RESPONSE_ACTIVE(msg)
            // this.SET_ACTIVE_CHANNEL_VIEW(platform)
            this.SET_ACTIVE_CHANNEL_VIEW(this.activeChannelView)
            // TODO: update tab view
          }
        } else {
          // click in integration message
          this.SET_MESSAGE_IN_EDIT()
          this.RESET_USER_MESSAGE()
        }
      }
    },
    hasAlternativePaths(message) {
      return message.prevAlternative || message.nextAlternative
    },
    hasBotResponse(msg) {
      if (msg.isBotMessage()) {
        return true
      }

      if (msg.isUserMessage() && msg.nextMessage) {
        const nextMessage = this._.find(this.botMakerMessages, m => {
          return m.id === msg.nextMessage
        })
        return nextMessage ? nextMessage.isBotMessage() : true
      }

      return false
    },
    dialogHasBotResponse(dialog) {
      return dialog.some(msg => msg.isBotMessage())
    },
    dialogHasValidation(dialogId) {
      return (
        this.dialogsMap[dialogId] &&
        this.dialogsMap[dialogId].sendValidationSettings &&
        this.dialogsMap[dialogId].sendValidationSettings.validation
      )
    },
    buildDialogs() {
      this.messagesByDialog = this.botMakerMessages.reduce((dialogs, item) => {
        return {
          ...dialogs,
          [item.dialogId]: [...(dialogs[item.dialogId] || []), item]
        }
      }, {})

      let currentDialogHasJumps = false
      let currentDialogHasSurveys = false
      let currentDialogHasValidation = false
      Object.keys(this.messagesByDialog).forEach(m => {
        currentDialogHasSurveys =
          this.dialogsMap[m] &&
          this.dialogsMap[m].sendSurveySettings &&
          this.dialogsMap[m].sendSurveySettings.surveyEnabled
        currentDialogHasJumps =
          this.dialogsMap[m] &&
          this.dialogsMap[m].jump &&
          this.dialogsMap[m].jump !== null
        currentDialogHasValidation =
          this.dialogsMap[m] &&
          this.dialogsMap[m].sendValidationSettings &&
          this.dialogsMap[m].sendValidationSettings.validationEnabled !== null
      })
      this.SET_DIALOG_JUMPS(currentDialogHasJumps)
      this.SET_DIALOG_SURVEYS(currentDialogHasSurveys)
      this.SET_DIALOG_VALIDATION(currentDialogHasValidation)
    },
    isRegex(text) {
      return text && text.startsWith('^(') && text.endsWith(')$')
    },
    messageName(msg) {
      // // const message = Helper.buildDialogName(msg)
      // // Helper.isDefaultName(msg.type.name)
      // //   ? Helper.buildDialogName(msg)
      //   : msg.type.name
      const query = ' and | or '
      const styles =
        'font-size: 0.6em; text-transform: uppercase; vertical-align: middle; font-weight: bold;'
      return msg.isUser === 0 || msg.isUserMessage()
        ? this.$options.filters.highlight(msg.type.name, query, styles)
        : msg.type.name
    }
  },
  watch: {
    botMakerMessages() {
      this.buildDialogs()
    },
    activeChannelView() {
      this.buildDialogs()
    },
    'bot.activeVersion'() {
      this.resetUserBox()
      this.resetBotBox()
    }
  },
  mounted() {
    this.buildDialogs()
  }
}
</script>
<style lang="scss">
.mobile-view-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 40px 15px 30px;

  &::-webkit-scrollbar {
    display: none;
  }

  .arrows-msg {
    cursor: pointer;
    min-width: 24px;
    position: relative;
    left: 0;
    top: 0;
    top: 150px;
    z-index: 9999;
    .arrow-back {
      width: 19px;
      float: left;
    }
    .arrow-forward {
      width: 19px;
      float: right;
    }
  }

  .items-start.mobile-container {
    position: relative;
  }

  .mobile-container {
    .api-call {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;

      .vs-divider {
        margin: 0;
        .vs-divider--text {
          background: unset;
        }
      }
      .feather-icon {
        cursor: pointer;
      }
    }
  }

  .dialog {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    // position: relative;

    // .integrations-icon {
    //   &.with-bot-message {
    //     position: absolute !important;
    //     bottom: 10px;
    //     right: 0px;
    //   }
    // }

    &.has-jump {
      .integrations-icon {
        &.with-bot-message {
          bottom: 55px;
        }
      }
    }
    .msg {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: left;
      white-space: pre-line;
      max-width: 85%;
      border: 1px solid transparent;
      cursor: pointer;
      word-break: break-word;

      &.user-message {
        background-color: #386aff;

        // &:before {
        //   content: '◤';
        //   position: absolute;
        //   color: #386aff;
        //   right: 0;
        //   bottom: 25px;
        //   width: 0;
        //   height: 0;
        //   margin-left: -12px;
        //   box-shadow: 0px 8px 10px 1.5px rgba(0, 0, 0, 0.3);
        // }
      }

      &.bot-message {
        cursor: move;
        // &:after {
        //   content: '◥';
        //   position: absolute;
        //   color: white;
        //   left: 0;
        //   bottom: 25px;
        //   width: 0;
        //   height: 0;
        //   margin-left: -13px;
        //   box-shadow: 0px 8px 10px 1.5px rgba(0, 0, 0, 0.3);
        // }
      }

      &.active {
        border: 2px solid orange;
      }
      // .img-message {
      //   max-width: 100%;
      // }
      .message-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      }
      .ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
      }
      .text {
        display: flex;
        flex-direction: row;
        align-items: center;
        .regex {
          font-size: 0.6rem;
          font-family: 'Courier New', Courier, monospace;
          font-weight: 500;
          padding: 0 2px;
          border: solid 1px white;
          border-radius: 3px;
          margin-right: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.mobile-view-message-icon {
  height: 15px;
}
.mobile-view-messages
  .dialog
  .integrations-icon.with-bot-message.integration-icon {
  bottom: 40px;
}
</style>
