<template>
  <div>
    <vs-popup
      classContent="popup-example message-nav-popup"
      :title="lang.contextVariables.title[languageSelected]"
      :active.sync="activePopup"
    >
      <div class="vs-con-loading__container" ref="contextVariables">
        <KonaTabs>
          <!-- cyberbank context variables -->
          <KonaTab
            :name="lang.botMaker.integrations.tabs.cyberbank[languageSelected]"
            :selected="
              useCyberbank &&
                cyberbankVariables &&
                cyberbankVariables.length > 0
            "
            v-if="
              useCyberbank &&
                cyberbankVariables &&
                cyberbankVariables.length > 0
            "
          >
            <vs-collapse accordion v-if="cyberbankVariables">
              <vs-collapse-item
                :key="index"
                v-for="(operatorName, index) in Object.keys(
                  cyberbankByOperator
                )"
                :open="index === 0"
              >
                <div slot="header">
                  {{ lang.contextVariables[operatorName][languageSelected] }}
                </div>

                <vs-table
                  stripe
                  :data="cyberbankByOperator[operatorName]"
                  v-if="cyberbankVariables"
                  class="cyberbank-variables-table"
                >
                  <template slot="thead">
                    <vs-th></vs-th>
                    <vs-th>
                      {{ lang.contextVariables.variable[languageSelected] }}
                    </vs-th>
                    <vs-th>
                      {{ lang.contextVariables.type[languageSelected] }}
                    </vs-th>
                    <vs-th></vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data"
                    >
                      <vs-td>
                        <vs-checkbox
                          :checked="isCBVarSelected(tr.params[0].id)"
                          @click.stop="toggleCBVarSelect(tr)"
                        />
                      </vs-td>
                      <vs-td :data="data[indextr].params[0].as">
                        {{ data[indextr].params[0].as }}
                      </vs-td>
                      <vs-td :data="data[indextr].params[0].type">
                        {{
                          data[indextr].params[0].type
                            ? lang.contextVariables.types[
                                data[indextr].params[0].type
                              ][languageSelected]
                            : ''
                        }}
                      </vs-td>
                      <vs-td>
                        <feather-icon
                          vs-type="flex"
                          vs-justify="center"
                          vs-align="center"
                          icon="TrashIcon"
                          class="mt-2 mr-2 cursor-pointer text-danger"
                          :class="{
                            'text-danger': !saving,
                            'text-grey': saving
                          }"
                          svgClasses="h-5 w-5"
                          :disabled="saving"
                          v-if="!readOnly"
                          @click="remove(index)"
                        />
                      </vs-td>

                      <template slot="expand">
                        <div class="vx-row expand-variables">
                          <div
                            class="vx-col"
                            v-if="data[indextr].params[0].operator"
                          >
                            <label>
                              •
                              {{
                                lang.contextVariables.operation[
                                  languageSelected
                                ]
                              }}:
                            </label>
                            <span>{{ data[indextr].params[0].operator }}</span>
                          </div>
                          <div
                            class="vx-col"
                            v-if="data[indextr].params[0].option"
                          >
                            <label>
                              •
                              {{
                                lang.contextVariables.option[languageSelected]
                              }}:
                            </label>
                            <span>
                              {{
                                data[indextr].params[0].option
                                  ? lang.contextVariables[
                                      data[indextr].params[0].option
                                    ][languageSelected]
                                  : ''
                              }}
                            </span>
                          </div>
                          <div
                            class="vx-col"
                            v-if="data[indextr].params[0].regex"
                          >
                            <label>
                              • RegExp:
                            </label>
                            <span>{{ data[indextr].params[0].regex }}</span>
                          </div>
                          <div
                            class="vx-col"
                            v-if="data[indextr].params[0].modifiers"
                          >
                            <label>
                              •
                              {{
                                lang.contextVariables.modifiers[
                                  languageSelected
                                ]
                              }}:
                            </label>
                            <span>
                              {{ data[indextr].params[0].modifiers }}
                            </span>
                          </div>
                          <div
                            class="vx-col"
                            v-if="data[indextr].params[0].key"
                          >
                            <label>
                              • Key:
                            </label>
                            <span>{{ data[indextr].params[0].key }}</span>
                          </div>
                          <div
                            class="vx-col"
                            v-if="data[indextr].params[0].value"
                          >
                            <label>
                              • Value:
                            </label>
                            <span>{{ data[indextr].params[0].value }}</span>
                          </div>
                          <div
                            class="vx-col"
                            v-if="data[indextr].params[0].copyOption"
                          >
                            <label>
                              • Copy option:
                            </label>
                            <span>
                              {{ data[indextr].params[0].copyOption }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-collapse-item>
            </vs-collapse>

            <div class="vx-row" v-if="!readOnly">
              <vs-col class="w-full flex flex-col">
                <div>
                  <vs-button
                    class="float-right mb-3"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="save"
                    :disabled="saving"
                  >
                    {{ lang.contextVariables.saveText[languageSelected] }}
                  </vs-button>
                  <vs-button
                    type="flat"
                    class="float-right mr-4 mb-3"
                    icon-pack="feather"
                    icon="icon-x"
                    color="danger"
                    @click="cancel"
                    :disabled="saving"
                  >
                    {{ lang.contextVariables.cancelText[languageSelected] }}
                  </vs-button>
                </div>
              </vs-col>
            </div>
          </KonaTab>

          <!-- context variables -->
          <KonaTab
            :name="lang.contextVariables.other[languageSelected]"
            :selected="
              !useCyberbank ||
                !cyberbankVariables ||
                (cyberbankVariables && cyberbankVariables.length === 0)
            "
          >
            <div class="vx-row">
              <vs-col class="w-full flex flex-col">
                <a
                  class="right mb-2 mt-2"
                  style="cursor: pointer;"
                  :disabled="saving"
                  @click="addAction"
                  v-if="!readOnly"
                >
                  {{
                    lang.contextVariables.addContextAction[languageSelected]
                  }}&nbsp;&nbsp;
                </a>
                <draggable :list="actions" class="cursor-move">
                  <div v-if="!actions.length" class="center mb-2 mt-2">
                    {{ lang.contextVariables.noActions[languageSelected] }}
                  </div>
                  <div v-else>
                    <div
                      v-for="(item, index) in actions"
                      :key="index + aux + conditionsKey"
                      class="mb-2"
                    >
                      <!-- <vs-divider position="center" class="text-primary">
                        ({{ index + 1 }})
                      </vs-divider> -->
                      <fieldset class="p-4 mb-4 rounded-lg action-wrapper">
                        <legend>
                          <!-- <span class="ml-2">({{ index + 1 }})</span> -->
                          <feather-icon
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                            icon="TrashIcon"
                            class="mt-2 mr-0 cursor-pointer text-danger"
                            :class="{
                              'text-danger': !saving,
                              'text-grey': saving
                            }"
                            svgClasses="h-5 w-5"
                            :disabled="saving"
                            v-if="!readOnly"
                            @click="remove(index)"
                          />
                        </legend>

                                              <!-- add condition -->
                      <vs-button
                        v-if="!readOnly && (!item.conditions || item.conditions.length === 0)"
                        type="flat"
                        size="small"
                        class="mb-3 "
                        @click="addCondition({ item, index })"
                      >
                        {{ lang.surveyMaker.conditions.add[languageSelected] }}
                      </vs-button>

                                              <!-- condition -->
                      <div
                        v-for="(condition, conditionIndex) in item.conditions"
                        :key="`${conditionIndex}${conditionsKey}`"
                        class="condition mb-4"
                      >
                        <!-- func -->
                        <div class="mr-4 vs-component vs-con-input-label w-1/3">
                          <label for="" class="vs-input--label">{{
                            lang.contextVariables.condition[languageSelected]
                          }}</label>
                          <multiselect
                            style="z-index: 99999999 !important;"
                            :allow-empty="false"
                            v-model="item.conditions[conditionIndex].func"
                            :showLabels="false"
                            :placeholder="
                              lang.contextVariables.condition[languageSelected]
                            "
                            :custom-label="
                              opt => SURVEY_CONDITIONS.find(c => c.value == opt).text
                            "
                            :options="SURVEY_CONDITIONS_KEYS"
                            @input="updateKey"
                            :disabled="readOnly"
                          />
                        </div>

                        <!-- args -->
                        <vs-input
                          :key="conditionsKey2"
                          class="mr-4 w-1/2"
                          :label="
                            lang.contextVariables.condition_arg[languageSelected]
                          "
                          v-model="condition.args[0]"
                          v-on:change.prevent="updateKey2"
                          :disabled="readOnly"
                        />

                        <vs-button
                          color="danger"
                          type="flat"
                          icon="icon-trash"
                          icon-pack="feather"
                          size="small"
                          class="ml-0 mt-6"
                          radius
                          :disabled="readOnly"
                          @click="removeCondition({ conditionIndex, index  })"
                        />
                      </div>

                      <vs-divider v-if="item.conditions && item.conditions.length" class="mt-base" />

                        <div class="vx-row">
                          <vs-col class="w-full">
                            <div
                              class="vx-row"
                              vs-type="flex"
                              vs-justify="center"
                            >
                              <vs-col vs-w="6">
                                <vs-input
                                  class="mt-0 w-full mb-4"
                                  v-model="item.as"
                                  :label="
                                    lang.contextVariables.variable[
                                      languageSelected
                                    ]
                                  "
                                  :disabled="saving || readOnly"
                                ></vs-input>
                              </vs-col>
                              <vs-col vs-w="6">
                                <vs-select
                                  class="mt-0 w-full mb-4"
                                  style="z-index: 999999 !important;"
                                  autocomplete
                                  :label="
                                    `${
                                      item.operator && item.operator.value
                                        ? lang.contextVariables[
                                            item.operator.value
                                              .split('/')[0]
                                              .toLowerCase()
                                          ][languageSelected]
                                        : lang.contextVariables['operation'][
                                            languageSelected
                                          ]
                                    }`
                                  "
                                  v-model="item.operator"
                                  :disabled="saving || readOnly"
                                >
                                  <div
                                    :key="index2"
                                    v-for="(item2, index2) in conditionOptions"
                                  >
                                    <vs-select-group
                                      :title="item2.title"
                                      v-if="item2.group"
                                    >
                                      <vs-select-item
                                        :key="index3"
                                        :value="item3"
                                        :text="item3.text"
                                        v-for="(item3, index3) in item2.group"
                                      />
                                    </vs-select-group>
                                  </div>
                                </vs-select>
                              </vs-col>
                              <!-- <vs-col
                                vs-w="2"
                                vs-type="flex"
                                vs-justify="center"
                                vs-align="center"
                              >
                                <feather-icon
                                  vs-type="flex"
                                  vs-justify="center"
                                  vs-align="center"
                                  icon="Trash2Icon"
                                  class="mt-2 pr-5 cursor-pointer text-danger"
                                  :class="{
                                    'text-danger': !saving,
                                    'text-grey': saving
                                  }"
                                  svgClasses="h-5 w-5"
                                  :disabled="saving"
                                  @click="remove(index)"
                                />
                              </vs-col> -->
                            </div>
                          </vs-col>
                        </div>
                        <div
                          class="vx-row"
                          v-if="
                            item.operator &&
                              item.operator.value &&
                              item.operator.value.split('/')[1] === 'all'
                          "
                        ></div>
                        <div
                          class="vx-row"
                          v-if="
                            item.operator &&
                              item.operator.value &&
                              item.operator.value.split('/')[1] === 'numbers'
                          "
                        >
                          <vs-col class="w-1/4">
                            <vs-select
                              class="mt-0 w-full mb-4"
                              style="z-index: 999999 !important;"
                              autocomplete
                              :label="
                                `
                                ${lang.contextVariables.option[languageSelected]} *`
                              "
                              v-model="item.option"
                              :disabled="readOnly"
                            >
                              <vs-select-item
                                :key="index2"
                                v-for="(item2, index2) in options"
                                :value="item2"
                                :text="item2.text"
                              />
                            </vs-select>
                          </vs-col>
                        </div>
                        <div
                          class="vx-row"
                          v-else-if="
                            item.operator &&
                              item.operator.value &&
                              item.operator.value.split('/')[1] === 'match'
                          "
                        >
                          <vs-col vs-w="3">
                            <vs-select
                              class="mt-0 w-full mb-4"
                              style="z-index: 999999 !important;"
                              autocomplete
                              :label="
                                lang.contextVariables.option[languageSelected]
                              "
                              v-model="item.option"
                              :disabled="readOnly"
                            >
                              <vs-select-item
                                :key="index2"
                                v-for="(item2, index2) in options"
                                :value="item2"
                                :text="item2.text"
                              />
                            </vs-select>
                          </vs-col>
                          <vs-col vs-w="6">
                            <vs-input
                              class="mt-0 w-full mb-4"
                              v-model="item.regex"
                              :label="'RegExp *'"
                              :disabled="saving || readOnly"
                            ></vs-input>
                          </vs-col>
                          <vs-col vs-w="3">
                            <vs-input
                              class="mt-0 w-full mb-4"
                              v-model="item.modifiers"
                              :label="
                                lang.contextVariables.modifiers[
                                  languageSelected
                                ]
                              "
                              :disabled="saving || readOnly"
                            ></vs-input>
                          </vs-col>
                        </div>
                        <div
                          class="vx-row"
                          v-else-if="
                            item.operator &&
                              item.operator.value &&
                              item.operator.value.split('/')[1] === 'literal'
                          "
                        >
                          <vs-col vs-w="6">
                            <vs-input
                              class="mt-0 w-full mb-4"
                              v-model="item.value"
                              :label="
                                lang.contextVariables.value[languageSelected]
                              "
                              :disabled="saving || readOnly"
                            ></vs-input>
                          </vs-col>
                        </div>
                        <div
                          class="vx-row"
                          v-else-if="
                            item.operator &&
                              item.operator.value &&
                              item.operator.value.split('/')[1] === 'copy'
                          "
                        >
                          <vs-col vs-w="3">
                            <vs-select
                              class="mt-0 w-full mb-4"
                              style="z-index: 999999 !important;"
                              autocomplete
                              :label="
                                lang.contextVariables.option[languageSelected]
                              "
                              v-model="item.copyOption"
                              :disabled="readOnly"
                            >
                              <vs-select-item
                                :key="index2"
                                v-for="(item2, index2) in copyOption"
                                :value="item2"
                                :text="item2.text"
                              />
                            </vs-select>
                          </vs-col>
                          <vs-col vs-w="6">
                            <vs-input
                              class="mt-0 w-full mb-4"
                              v-model="item.key"
                              :label="
                                lang.contextVariables.variableToCopy[
                                  languageSelected
                                ]
                              "
                              :disabled="saving || readOnly"
                            ></vs-input>
                          </vs-col>
                        </div>
                        <div
                          class="vx-row"
                          v-else-if="
                            item.operator &&
                              item.operator.value &&
                              item.operator.value.split('/')[1] === 'drop'
                          "
                        ></div>
                      </fieldset>
                    </div>
                  </div>
                </draggable>
                <div v-if="!readOnly">
                  <vs-button
                    class="float-right mb-3"
                    icon-pack="feather"
                    icon="icon-save"
                    @click="save"
                    :disabled="saving"
                    >{{
                      lang.contextVariables.saveText[languageSelected]
                    }}</vs-button
                  >
                  <vs-button
                    type="flat"
                    class="float-right mr-4 mb-3"
                    icon-pack="feather"
                    icon="icon-x"
                    color="danger"
                    @click="cancel"
                    :disabled="saving"
                    >{{
                      lang.contextVariables.cancelText[languageSelected]
                    }}</vs-button
                  >
                </div>
              </vs-col>
            </div>
          </KonaTab>
        </KonaTabs>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'

// const CONDITION_MODEL = {
//   func: '',
//   args: [],
//   jump: {
//     group: '',
//     name: ''
//   }
// }

export default {
  name: 'ContextVariables',
  props: {
    dialogId: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    draggable,
    KonaTabs: () => import('@/components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('@/components/kona-tabs/KonaTab.vue'),
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd'),
    Multiselect: () => import('vue-multiselect'),
  },
  data() {
    return {
      SURVEY_CONDITIONS: [],
      SURVEY_CONDITIONS_KEYS: [],

      activePopup: false,
      saving: false,

      condition: null,

      aux: 0,
      actions: [],
      selectedCBVars: [],
      conditionsKey: Date.now(),
      conditionsKey2: Date.now() + 1,
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'useCyberbank']),
    ...mapGetters('botMaker', [
      'dialogsMap',
      'userMessageInEdit',
      'botMessageInEdit',
      'botMakerMessages',
      'cyberbankVariables'
    ]),
    conditionOptions() {
      return [
        {
          title: this.lang.contextVariables.overwrite[this.languageSelected],
          group: [
            {
              text: this.lang.contextVariables.allText[this.languageSelected],
              value: 'overwrite/all'
            },
            {
              text: this.lang.contextVariables.numbersOnly[
                this.languageSelected
              ],
              value: 'overwrite/numbers'
            },
            {
              text: this.lang.contextVariables.matchRexExp[
                this.languageSelected
              ],
              value: 'overwrite/match'
            },
            {
              text: this.lang.contextVariables.literal[this.languageSelected],
              value: 'overwrite/literal'
            },
            {
              text: this.lang.contextVariables.copyVariable[
                this.languageSelected
              ],
              value: 'overwrite/copy'
            },
            {
              text: this.lang.contextVariables.markAsSensible[
                this.languageSelected
              ],
              value: 'overwrite/sensible'
            }
            
          ]
        },
        {
          title: this.lang.contextVariables.append[this.languageSelected],
          group: [
            {
              text: this.lang.contextVariables.allText[this.languageSelected],
              value: 'append/all'
            },
            {
              text: this.lang.contextVariables.numbersOnly[
                this.languageSelected
              ],
              value: 'append/numbers'
            },
            {
              text: this.lang.contextVariables.matchRexExp[
                this.languageSelected
              ],
              value: 'append/match'
            },
            {
              text: this.lang.contextVariables.literal[this.languageSelected],
              value: 'append/literal'
            },
            {
              text: this.lang.contextVariables.copyVariable[
                this.languageSelected
              ],
              value: 'append/copy'
            }
          ]
        },
        {
          title: this.lang.contextVariables.drop[this.languageSelected],
          group: [
            {
              text: this.lang.contextVariables.dropVariable[
                this.languageSelected
              ],
              value: 'drop/drop'
            }
          ]
        }
      ]
    },
    overwrite() {
      return this.lang.contextVariables.overwrite[this.languageSelected]
    },
    append() {
      return this.lang.contextVariables.append[this.languageSelected]
    },
    drop() {
      return this.lang.contextVariables.drop[this.languageSelected]
    },
    operation() {
      return this.lang.contextVariables.operation[this.languageSelected]
    },
    options() {
      return [
        {
          text: this.lang.contextVariables.first[this.languageSelected],
          value: 'first'
        },
        {
          text: this.lang.contextVariables.all[this.languageSelected],
          value: 'all'
        },
        {
          text: this.lang.contextVariables.last[this.languageSelected],
          value: 'last'
        }
      ]
    },
    copyOption() {
      return [
        {
          text: this.lang.contextVariables.copy[this.languageSelected],
          value: ''
        },
        {
          text: this.lang.contextVariables.reverse[this.languageSelected],
          value: 'reverse'
        },
        {
          text: this.lang.contextVariables.sort[this.languageSelected],
          value: 'sort'
        }
      ]
    },
    cyberbankByOperator() {
      if (!this.cyberbankVariables) return
      const result = this.cyberbankVariables.reduce(function(r, a) {
        r[a.params[0].operator] = r[a.params[0].operator] || []
        r[a.params[0].operator].push(a)
        return r
      }, {})
      return result
    }
  },
  methods: {
    ...mapActions('credentials', ['CREATE_NLU']),
    ...mapActions('botMaker', [
      'UPDATE_DIALOG_ACTIONS',
      'GET_CYBERBANK_VARIABLES'
    ]),
    updateKey() {
      this.conditionsKey = Date.now()
    },
    updateKey2() {
      this.conditionsKey2 = Date.now()
    },
    removeCondition({ index, conditionIndex }) {
      this.updateKey()
      this.$nextTick(() => {
        this.actions[index].conditions.splice(conditionIndex, 1)
        this.actions[index].conditions = JSON.parse(
          JSON.stringify(this.actions[index].conditions)
        )
        this.updateKey()
      })
    },
    addCondition({ index }) {
      if (!this.actions[index].conditions) {
        this.actions[index].conditions = []
      }
      this.actions[index].conditions.push({
        func: '',
        args: [],
      })
      this.updateKey()
    },
    initConditions() {
      this.SURVEY_CONDITIONS = [
        {
          text: this.lang.surveyMaker.conditions.texts.lessThan[
            this.languageSelected
          ],
          value: 'lessThan'
        },
        {
          text: this.lang.surveyMaker.conditions.texts.greaterThan[
            this.languageSelected
          ],
          value: 'greaterThan'
        },
        {
          text: this.lang.surveyMaker.conditions.texts.equal[
            this.languageSelected
          ],
          value: 'equal'
        },
        // { text: 'Not Equal', value: 'not_equal' },
        {
          text: this.lang.surveyMaker.conditions.texts.match[
            this.languageSelected
          ],
          value: 'match'
        }
      ]
      this.SURVEY_CONDITIONS_KEYS = this.SURVEY_CONDITIONS.map(c => c.value)
    },
    isCBVarSelected(varId) {
      return this.selectedCBVars.find(cbVar => cbVar.params[0].id === varId)
    },
    toggleCBVarSelect(tr) {
      const indexSelectedCbVar = this.selectedCBVars.findIndex(
        cbVar => cbVar.params[0].id === tr.params[0].id
      )
      if (indexSelectedCbVar !== -1) {
        this.selectedCBVars.splice(indexSelectedCbVar, 1)
      } else {
        this.selectedCBVars.push(tr)
      }
    },
    remove(index) {
      if (this.saving) {
        return
      }
      this.actions.splice(index, 1)
    },
    translate(text) {
      if (this[text.toLowerCase()]) {
        return this[text.toLowerCase()]
      }
      return 'N/A'
    },
    async openPopup() {
      this.activePopup = true
      if (this.useCyberbank) {
        await this.getCyberbankVariables()
      }
      this.updateFields()
    },
    updateFields() {
      if (
        this.dialogId &&
        this.dialogsMap[this.dialogId] &&
        this.dialogsMap[this.dialogId].actions &&
        this.dialogsMap[this.dialogId].actions.length > 0
      ) {
        this.selectedCBVars = this.dialogsMap[this.dialogId].actions.filter(
          a => a.params[0].from === 'cyberbank'
        )
        this.actions = []
        const otherActions = this.dialogsMap[this.dialogId].actions.filter(
          a => !a.params[0].from || a.params[0].from !== 'cyberbank'
        )
        otherActions.forEach(element => {
          if (
            element.key &&
            element.key === 'save variable' &&
            element.params &&
            element.params[0]
          ) {
            const params = element.params[0]
            const item = {
              operator: {
                text: 'TO DO',
                value: ''
              }
            }
            let aux = 0
            switch (params.operator) {
              case 'overwrite':
                item.operator.value += 'overwrite'
                break
              case 'markAsSensible':
                item.operator.value += 'markAsSensible'
                break
              case 'append':
                aux = 1
                item.operator.value += 'append'
                break
              case 'drop':
                item.operator.value += 'drop'
                this.conditionOptions[2].group[0]
                break
              default:
                break
            }
            if (item.operator.value.length > 0) {
              item.operator.value += `/${params.type}`
              item.as = params.as
              item.option = null
              item.regex = ''
              item.modifiers = ''
              item.value = ''
              item.key = ''
              item.copyOption = null
              switch (params.type) {
                case 'all':
                  item.operator.text = 'Todo el texto'
                  item.operator = this.conditionOptions[aux].group[0]
                  break
                case 'numbers':
                  item.operator.text = 'Sólo números'
                  item.operator = this.conditionOptions[aux].group[1]
                  if (params.option === 'first') {
                    item.option = this.options[0]
                  } else if (params.option === 'all') {
                    item.option = this.options[1]
                  } else if (params.option === 'last') {
                    item.option = this.options[2]
                  }
                  break
                case 'match':
                  item.operator.text = 'Match de RegExp'
                  item.regex = params.regex
                  item.modifiers = params.modifiers
                  item.operator = this.conditionOptions[aux].group[2]
                  if (params.option === 'first') {
                    item.option = this.options[0]
                  } else if (params.option === 'all') {
                    item.option = this.options[1]
                  } else if (params.option === 'last') {
                    item.option = this.options[2]
                  }
                  break
                case 'literal':
                  item.operator.text = 'Valor'
                  item.value = params.value
                  item.operator = this.conditionOptions[aux].group[3]
                  break
                case 'copy':
                  item.operator.text = 'Copia de variable'
                  item.key = params.key
                  item.operator = this.conditionOptions[aux].group[4]
                  if (params.option === '') {
                    item.copyOption = this.copyOption[0]
                  } else if (params.option === 'all') {
                    item.copyOption = this.copyOption[1]
                  } else if (params.option === 'last') {
                    item.copyOption = this.copyOption[2]
                  }
                  break
                default:
                  break
              }
              if (element.conditions) {
                item.conditions = element.conditions
              }
              this.actions.push(item)
            }
          }
        })
      } else {
        this.actions = [
          {
            as: '',
            operator: this.conditionOptions[0].group[0],
            option: null,
            regex: '',
            modifiers: '',
            value: '',
            key: '',
            copyOption: null
          }
        ]
      }
      this.key += 1
    },
    addAction() {
      this.actions.push({
        as: '',
        operator: {},
        option: null,
        regex: '',
        modifiers: '',
        value: '',
        key: ''
      })
    },
    cancel() {
      this.activePopup = false
    },
    getModifiers(modifiers) {
      if (!modifiers || !(typeof modifiers === 'string')) {
        return 'g'
      }
      if (/g/.test(modifiers)) {
        return modifiers
      }
      return `${modifiers}g`
    },
    validate(item) {
      if (!item || !item.operator || !item.operator.value) {
        return 'operator'
      }
      if (!item.as || item.as.trim().length === 0) {
        return 'as_length'
      }
      if (!/^[a-z0-9]+$/i.test(item.as.trim())) {
        return 'as_alpha'
      }
      if (
        ['overwrite', 'append'].indexOf(
          item.operator.value.split('/')[0].toLowerCase()
        ) > -1
      ) {
        switch (item.operator.value.split('/')[1].toLowerCase()) {
          case 'all':
            return 'ok'
          case 'sensible':
            return 'ok'
          case 'numbers':
            return item.option &&
              item.option.value &&
              ['first', 'all', 'last'].indexOf(item.option.value) > -1
              ? 'ok'
              : 'option'
          // eslint-disable-next-line no-case-declarations
          case 'match':
            if (
              !item.option ||
              !item.option.value ||
              ['first', 'all', 'last'].indexOf(item.option.value) === -1
            ) {
              return 'option'
            }
            if (!item.regex || item.regex.trim().length === 0) {
              return 'regex'
            }
            try {
              // eslint-disable-next-line no-unused-vars
              const regex = new RegExp(item.regex, 'g')
              regex.test('holis') // para intentar que explote
              try {
                // eslint-disable-next-line no-unused-vars
                const regex = new RegExp(
                  item.regex,
                  this.getModifiers(item.modifiers)
                )
                regex.test('holis') // para intentar que explote
              } catch (_) {
                return 'modifiers'
              }
            } catch (_) {
              return 'regex'
            }
            return 'ok'
          case 'literal':
            return 'ok'
          case 'copy':
            if (
              !item.copyOption ||
              item.copyOption.value === null ||
              item.copyOption.value === undefined ||
              ['', 'reverse', 'sort'].indexOf(item.copyOption.value) === -1
            ) {
              return 'option'
            }
            if (!item.key || item.key.trim().length === 0) {
              return 'key'
            }
            return 'ok'
          default:
            return 'operator'
        }
      } else if (
        ['drop'].indexOf(item.operator.value.split('/')[0].toLowerCase()) > -1
      ) {
        return 'ok'
      } else {
        return 'operator'
      }
    },
    mutate(item) {
      const result = {
        as: item.as.trim(),
        operator: item.operator.value.split('/')[0].toLowerCase()
      }
      if (['overwrite', 'append'].indexOf(result.operator) > -1) {
        result.type = item.operator.value.split('/')[1].toLowerCase()
        switch (result.type) {
          case 'all':
            return {
              key: 'save variable',
              params: [result],
              conditions: item.conditions || []
            }
          case 'sensible':
            result.dataPrivacy = 'sensible';
            return {
              key: 'save variable',
              params: [result],
              conditions: item.conditions || []
            }
          case 'numbers':
            result.option = item.option.value
            return {
              key: 'save variable',
              params: [result],
              conditions: item.conditions || []
            }
          case 'match':
            result.option = item.option.value
            result.regex = item.regex.trim()
            result.modifiers = item.modifiers.trim()
            return {
              key: 'save variable',
              params: [result],
              conditions: item.conditions || []
            }
          case 'literal':
            result.value = item.value
            return {
              key: 'save variable',
              params: [result],
              conditions: item.conditions || []
            }
          case 'copy':
            result.option = item.copyOption.value
            result.key = item.key.trim()
            return {
              key: 'save variable',
              params: [result],
              conditions: item.conditions || []
            }
          default:
            return
        }
      } else if (['drop'].indexOf(result.operator) > -1) {
        return {
          key: 'save variable',
          params: [result],
          conditions: item.conditions || []
        }
      }
    },
    async save() {
      let error = false
      this.actions.forEach((element, index) => {
        const validation = this.validate(element)
        if (validation !== 'ok') {
          error = true
          return this.$vs.notify({
            title: this.lang.contextVariables.validationError[
              this.languageSelected
            ],
            text: `(${index + 1}) ${
              this.lang.contextVariables.validationMessage[validation][
                this.languageSelected
              ]
            }`,
            color: 'warning'
          })
        }
      })
      if (!error) {
        this.$vs.loading()
        const mutated = this.actions.map(element => this.mutate(element))
        mutated.push(...this.selectedCBVars)
        this.saving = true

        let dialogSavedId = this.userMessageInEdit.isEditing
          ? this.userMessageInEdit.dialogSavedId
          : this.botMessageInEdit.dialogSavedId

        // if no existing message selected create empty text message to add integration
        if (!dialogSavedId) {
          const messageId = this.botMessageInEdit.id
          await this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
            .then(() => {
              const createdMessage = this.getMessageById(messageId)
              dialogSavedId = createdMessage.dialogSavedId
            })
            .catch(error => {
              this.$log.error(error)
            })
        }

        this.UPDATE_DIALOG_ACTIONS({
          dialogSavedId,
          actions: mutated
        })
          .then(async () => {
            this.$vs.notify({
              title: this.lang.contextVariables.success.title[
                this.languageSelected
              ],
              text: this.lang.contextVariables.success.text[
                this.languageSelected
              ],
              color: 'success'
            })
            this.saving = false
            this.activePopup = false
          })
          .catch(error => {
            this.$log.error(error)
            this.$vs.notify({
              title: this.lang.contextVariables.error.title[
                this.languageSelected
              ],
              text: this.lang.contextVariables.error.text[
                this.languageSelected
              ],
              color: 'danger'
            })
            this.saving = false
          })
          .finally(() => {
            this.$vs.loading.close()
          })
      } else {
        this.$vs.loading.close()
      }
    },
    async getCyberbankVariables() {
      try {
        this.$vs.loading({ container: this.$refs.contextVariables })
        await this.GET_CYBERBANK_VARIABLES()
        this.$vs.loading.close(this.$refs.contextVariables)
      } catch (error) {
        console.warn(error)
        this.$vs.loading.close(this.$refs.contextVariables)
      }
    },
  },
  created() {
    this.initConditions()
  }
}
</script>

<style lang="scss">
.vs-select--options {
  z-index: 99999 !important;
}
.vs-select-group h4 {
  padding-top: 5px;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.action-wrapper {
  border: solid 1px #cecece;
}
.vs-collapse-item--content {
  overflow-y: auto;
}
.cyberbank-variables-table {
  .vs-table--tbody-table tr {
    cursor: pointer;
  }
  .expand-variables {
    padding: 20px;
  }
}
.condition {
  display: flex;
  flex-grow: 1;
  width: auto;
  align-items: center;
}
.multiselect__content-wrapper {
  z-index: 99999999 !important;
}
</style>
