<template>
  <vs-row v-if="finishedLoading">
    <vs-col vs-w="12" v-if="showForking">
      <div class="forkingCompleted">
        <div class="percentage">
          <span>
            {{ lang.forking.forkingTitle[forkType][languageSelected] }}
          </span>
          <span>{{ forkingCompleted }}%</span>
        </div>

        <vs-progress :percent="forkingCompleted" color="primary" :height="10" />
      </div>
      <forking :key="forkingKey" />
    </vs-col>

    <!-- Bot messages -->
    <vs-col vs-w="4" v-if="showBotSays">
      <BotSays :read-only="isReadOnly"/>
      <!-- <br />
      <alternative-messages :is-left="isLeft" />-->
    </vs-col>

    <!-- Messages -->
    <vs-col vs-w="4" vs-justify="center" v-if="showMobileView">
      <MobileView :read-only="isReadOnly"/>
    </vs-col>

    <!-- User messages -->
    <vs-col vs-w="4" v-if="showUserSays">
      <UserSays :read-only="isReadOnly"/>
      <!-- <alternative-messages /> -->
    </vs-col>
  </vs-row>
</template>

<script>
// components
import MobileView from './components/MobileView'
import UserSays from './components/UserSays'
import BotSays from './components/BotSays'
// import Platforms from './components/Platforms'
// import Environment from './components/Environment'
// import MessageNav from './components/messageNav/MessageNav'
// import AlternativeMessages from './components/AlternativeMessages'
// import BotEditorNavBar from './components/BotEditorNavbar'
import Forking from './components/Forking'

// vuex
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  components: {
    MobileView,
    UserSays,
    BotSays,
    // Platforms,
    // Environment,
    // MessageNav,
    // AlternativeMessages,
    // BotEditorNavBar,
    Forking
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      isLeft: true,
      finishedLoading: false,
      showEditorNavBarOptions: false,
      showBotSays: false,
      showMobileView: false,
      showUserSays: false,
      showForking: false,
      forkingInterval: null,
      forkingKey: 0,
      forkingCompleted: 0
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'bot',
      'dialogs',
      'userMessageInEdit',
      'botId',
      'dialogMsgId',
      'botVersions'
    ]),
    forkType() {
      if (this.bot && this.bot.forkTemplate) return 'template'
      if (this.bot && this.bot.fork) return 'copy'
      return 'copy'
    },
    isReadOnly() {
      return !this.isAuthAux('konecta.bot_maker.edit_bot', true)
    }
  },
  methods: {
    ...mapMutations('botMaker', [
      'START_STORE',
      'SET_BOT_ID',
      'SET_MESSAGE_IN_EDIT',
      'LOAD_ALTERNATIVE_PATH',
      'SET_CONDITIONS_IN_EDIT',
      'SET_ACTIVE_CHANNEL_VIEW'
    ]),
    ...mapMutations('botMaker', ['SET_BOT']),
    ...mapActions('botMaker', [
      'GET_BOT',
      'GET_ACTIVE_VERSION',
      'GET_INTENTIONS',
      'GET_DIALOGS',
      'GET_BOT_VERSIONS'
    ]),
    ...mapActions('credentials', ['GET_NLU']),
    updateForkingCompleted() {
      const totalElems = this.bot.fork
        ? this.bot.fork.entities.length + this.bot.fork.intents.length
        : this.bot.forkTemplate.entities.length +
          this.bot.forkTemplate.intents.length

      const doneEntites = this.bot.fork
        ? this.bot.fork.entities.filter(e => e.status === 0).length
        : this.bot.forkTemplate.entities.filter(e => e.status === 0).length

      const doneIntents = this.bot.fork
        ? this.bot.fork.intents.filter(i => i.status === 0).length
        : this.bot.forkTemplate.intents.filter(i => i.status === 0).length

      const doneElems = doneEntites + doneIntents

      let forkingPerc = Math.round((doneElems / totalElems) * 100)
      if (forkingPerc === 0) forkingPerc = 1
      if (forkingPerc === 100) forkingPerc = 99

      this.forkingCompleted = forkingPerc
    },
    isForkingBot() {
      return this.bot.fork && this.bot.fork.status && this.bot.fork.status === 1
    },
    isForkingTemplate() {
      return (
        this.bot.forkTemplate &&
        this.bot.forkTemplate.status &&
        this.bot.forkTemplate.status === 1
      )
    }
  },
  async mounted() {
    window.scrollTo(0, 0)

    const botId = this.$route.query.botId
    const serviceId = this.$route.query.serviceId

    if (
      !this.dialogs ||
      this.botId !== botId ||
      this.bot.service.id !== serviceId
    ) {
      this.$vs.loading()
      this.START_STORE()

      this.SET_BOT_ID(botId)

      try {
        clearInterval(this.forkingInterval)
        await this.GET_BOT(botId)
        await this.GET_NLU()
        this.SET_BOT(this.bot)
        if (this.bot.legacy) {
          this.$notify.warning(
            this.lang.botMaker.legacyBot[this.languageSelected]
          )
          this.$router.push('/bot-maker/bots')
        } else {
          await this.GET_ACTIVE_VERSION([serviceId, this.$route.query.version])
          await this.GET_BOT_VERSIONS([serviceId, this.$route.query.version])
          if (this.isForkingBot() || this.isForkingTemplate()) {
            this.showForking = true
            this.forkingInterval = setInterval(async () => {
              await this.GET_ACTIVE_VERSION([
                serviceId,
                this.$route.query.version
              ])
              await this.GET_BOT_VERSIONS([
                serviceId,
                this.$route.query.version
              ])
              if (this.isForkingBot() || this.isForkingTemplate()) {
                this.forkingKey++
                this.updateForkingCompleted()
              } else {
                // reload when copy is done
                this.$router.go()
              }
            }, 5000)
          } else {
            this.showBotSays = true
            this.showMobileView = true
            this.showUserSays = true
            this.showEditorNavBarOptions = true
          }
          await this.GET_INTENTIONS()
          await this.GET_DIALOGS(undefined)
        }
        this.finishedLoading = true
      } catch (error) {
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.error[this.languageSelected],
          color: 'danger'
        })
        this.$log.error(error)
        return await Promise.reject(error)
      }

      this.$vs.loading.close()
      return
    }

    if (serviceId && !this.bot.activeVersion) {
      await this.GET_ACTIVE_VERSION([serviceId, this.$route.query.version])
      await this.GET_BOT_VERSIONS([serviceId, this.$route.query.version])
    }

    await this.GET_INTENTIONS()
    if (this.dialogs) {
      this.showBotSays = true
      this.showMobileView = true
      this.showUserSays = true
      this.showEditorNavBarOptions = true
      this.SET_ACTIVE_CHANNEL_VIEW()
      if (this.dialogMsgId) {
        this.SET_MESSAGE_IN_EDIT(this.dialogMsgId)
        // this.SET_TAGS_IN_EDIT(this.dialogMsgId)
        this.LOAD_ALTERNATIVE_PATH(this.userMessageInEdit)
        this.SET_CONDITIONS_IN_EDIT(this.userMessageInEdit)
      }
      this.finishedLoading = true
    }
  },
  beforeDestroy() {
    clearInterval(this.forkingInterval)
  }
}
</script>

<style lang="scss">
.forkingCompleted {
  .percentage {
    display: flex;
    justify-content: space-between;
    span {
      font-size: 1.2rem;
    }
  }
}
</style>
