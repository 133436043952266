<template>
  <div class="forking">
    <!-- <h4 class="mt-4">{{ lang.forking.forkingTitle[languageSelected] }}</h4> -->
    <div class="vx-row mt-base">
      <div class="vx-col md:w-1/2 w-full">
        <vx-card
          :title="lang.forking.intentsTitle[languageSelected]"
          :subtitle="cardSubtitle('intents')"
          class="contained-example-container"
        >
          <vs-table
            :data="intents"
            pagination
            :max-items="maxItems"
            search
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th>{{ lang.forking.intent[languageSelected] }}</vs-th>
              <vs-th>{{ lang.forking.status[languageSelected] }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>
                <vs-td :data="data[indextr].status">
                  <vs-chip :color="statusColor(data[indextr].status)">{{
                    data[indextr].statusText
                  }}</vs-chip>
                </vs-td>
                <template slot="expand" v-if="data[indextr].error">
                  <div style="padding: 1.5em">
                    <div v-if="data[indextr].error.message">
                      <span class="text-danger">{{
                        data[indextr].error.message
                      }}</span>
                      <vs-divider></vs-divider>
                    </div>

                    <span>{{ data[indextr].error }}</span>
                  </div>
                </template>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
      <div class="vx-col md:w-1/2 w-full">
        <vx-card
          :title="lang.forking.entitiesTitle[languageSelected]"
          :subtitle="cardSubtitle('entities')"
          class="contained-example-container"
        >
          <vs-table
            :data="entities"
            pagination
            :max-items="maxItems"
            search
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th>{{ lang.forking.intent[languageSelected] }}</vs-th>
              <vs-th>{{ lang.forking.status[languageSelected] }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name
                }}</vs-td>
                <vs-td :data="data[indextr].status">
                  <vs-chip :color="statusColor(data[indextr].status)">{{
                    data[indextr].statusText
                  }}</vs-chip>
                </vs-td>
                <template slot="expand" v-if="data[indextr].error">
                  <div style="padding: 1.5em">
                    <div v-if="data[indextr].error.message">
                      <span class="text-danger">{{
                        data[indextr].error.message
                      }}</span>
                      <vs-divider></vs-divider>
                    </div>

                    <span>{{ data[indextr].error }}</span>
                  </div>
                </template>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Forking',
  data() {
    return {
      maxItems: 10
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot']),
    // fork() {
    //   return this.bot.fork || null
    // },
    intents() {
      let intents = []
      if (this.bot.fork) {
        intents = this.bot.fork.intents
      } else if (this.bot.forkTemplate) {
        intents = this.bot.forkTemplate.intents
      }
      return intents.map(element => ({
        name: element.name,
        status: element.status,
        statusText: this.statusText(element.status),
        error: element.error
      }))
    },
    entities() {
      let entities = []
      if (this.bot.fork) {
        entities = this.bot.fork.entities
      } else if (this.bot.forkTemplate) {
        entities = this.bot.forkTemplate.entities
      }
      return entities.map(element => ({
        name: element.name,
        status: element.status,
        statusText: this.statusText(element.status),
        error: element.error
      }))
    }
  },
  methods: {
    statusColor(status) {
      let aux = parseInt(status)
      if (aux === 1) {
        return 'primary'
      }
      if (aux === 0) {
        return 'success'
      }
      if (aux === -1) {
        return 'danger'
      }
      return ''
    },
    statusName(status) {
      let aux = parseInt(status)
      if (aux === 1) {
        return 'statusPending'
      }
      if (aux === 0) {
        return 'statusDone'
      }
      if (aux === -1) {
        return 'statusError'
      }
      return 'statusError'
    },
    statusText(status) {
      return this.lang.forking[this.statusName(status)][this.languageSelected]
    },
    cardSubtitle(collection) {
      const pending = this[collection].filter(element => element.status === 1)
        .length
      const done = this[collection].filter(element => element.status === 0)
        .length
      const error = this[collection].filter(element => element.status === -1)
        .length
      return `${this.statusText(0)}: ${done} / ${this.statusText(
        1
      )}: ${pending} / ${this.statusText(-1)}: ${error}`
    }
  }
}
</script>
